export const Diversos = {
  
  getUFs: () => {
    // CRIA LISTA DE ESTADOS
    return [
      { value: "0", label: "--" },
      { value: "AC", label: "Acre" },
      { value: "AL", label: "Alagoas" },
      { value: "AP", label: "Amapá" },
      { value: "AM", label: "Amazonas" },
      { value: "BA", label: "Bahia" },
      { value: "CE", label: "Ceará" },
      { value: "DF", label: "Distrito Federal" },
      { value: "ES", label: "Espirito Santo" },
      { value: "GO", label: "Goiás" },
      { value: "MA", label: "Maranhão" },
      { value: "MS", label: "Mato Grosso do Sul" },
      { value: "MT", label: "Mato Grosso" },
      { value: "MG", label: "Minas Gerais" },
      { value: "PA", label: "Pará" },
      { value: "PB", label: "Paraíba" },
      { value: "PR", label: "Paraná" },
      { value: "PE", label: "Pernambuco" },
      { value: "PI", label: "Piauí" },
      { value: "RJ", label: "Rio de Janeiro" },
      { value: "RN", label: "Rio Grande do Norte" },
      { value: "RS", label: "Rio Grande do Sul" },
      { value: "RO", label: "Rondônia" },
      { value: "RR", label: "Roraima" },
      { value: "SC", label: "Santa Catarina" },
      { value: "SP", label: "São Paulo" },
      { value: "SE", label: "Sergipe" },
      { value: "TO", label: "Tocantins" }
    ];
  },
  
  getSexos: () => {
    return [
      { value: 1, label: "Masculino" },
      { value: 2, label: "Feminino" },
      { value: 3, label: "Não informar" }
    ];
  },
  
  
  /**
  *   left padding s with c to a total of n chars
  */
  padding_left: (s, c, n) =>  {
    if (!s || !c || s.length >= n) {
      return s;
    }
    var max = (n - s.length) / c.length;
    for (var i = 0; i < max; i++) {
      s = c + s;
    }
    return s;
  },
  
  /**
  *   right padding s with c to a total of n chars
  */
  padding_right: (s, c, n) =>  {
    if (!s || !c || s.length >= n) {
      return s;
    }
    var max = (n - s.length) / c.length;
    for (var i = 0; i < max; i++) {
      s += c;
    }
    return s;
  },
  
  /**
  *   FUNÇÃO PARA RETORNO APENAS NUMEROS DE UMA STRING
  *   @param string
  *   @return int pertencentes a string
  */
  
  getnums: (str) =>  {
    var num = str.toString().replace(/[^0-9]/g, '');
    return num;
  },
  
  /**
  *   FUNÇÃO PARA CONVERTER STRING DATA DO TIPO YYYY-MM-DD HH:MM:SS EM DD/MM/YYYY HH:MM:SS
  *   @param string tipo YYYY-MM-DD HH:MM:SS
  *   @return DD/MM/YYYY HH:MM:SS
  */
  
  convData: (data) => {
    if ((data == "") || (data == null))
      return "// ::";
    // return data[8] + data[9] + "/" + data[5] + data[6] + "/" + data[0] + data[1] + data[2] + data[3] + " " + data[11] + data[12] + ":" + data[14] + data[15] + ":" + data[17] + data[18];
    // return data.substr(8, 2) + "/" + data.substr(5, 2) + "/" + data.substr(0, 4) + "/" + data.substr(11, 2) + ":" + data.substr(14, 2) + ":" + data.substr(17, 2);

    // if ( data.length > 10 )
    //   return data.substr(8, 2) + "/" + data.substr(5, 2) + "/" + data.substr(0, 4) + " " + data.substr(11, 2) + ":" + data.substr(14, 2);
    // else
      return data.substr(8, 2) + "/" + data.substr(5, 2) + "/" + data.substr(0, 4);
  },
  
  convDataToBD: (data) => {
    if ((data == "") || (data == null))
    return "--";
    // return data[8] + data[9] + "/" + data[5] + data[6] + "/" + data[0] + data[1] + data[2] + data[3] + " " + data[11] + data[12] + ":" + data[14] + data[15] + ":" + data[17] + data[18];
    return data.substr(6, 4) + "-" + data.substr(3, 2) + "-"+ data.substr(0, 2);
  },

  getDiaSemana: (data) => {
    let auxDate = new Date( data );
    let diaSemana = "";
    switch ( auxDate.getDay() ) {
      case 0: 
        diaSemana = "Domingo";
        break;
      case 1: 
        diaSemana = "Segunda";
        break;
      case 2: 
        diaSemana = "Terça";
        break;
      case 3: 
        diaSemana = "Quarta";
        break;
      case 4: 
        diaSemana = "Quinta";
        break;
      case 5: 
        diaSemana = "Sexta";
        break;
      case 6: 
        diaSemana = "Sábado";
        break;
    }
    return diaSemana;
  },
  
  /**
  *    FUNÇÃO PARA FORMATAR STRING DE VALOR NO FORMATO DE MOEDA (IGUAL AO PHP)
  *    @param float valor - Ex.: 4.40
  *    @return string valor - E.: 4,40
  */
  number_format: (number, decimals, dec_point, thousands_sep) => {
    number = (number + '')
    .replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = [''],
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k)
      .toFixed(prec);
    };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
    .split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
    .length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1)
      .join('0');
    }
    return s.join(dec);
  },
  
  /**
  *	FUNÇÃO PARA VERIFICA SE STRING DE E-MAIL É VÁLIDO
  *	@param string email
  *	@return bool
  */
  
  validateEmail: (sEmail) => {
    var filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
    if (filter.test(sEmail))
    return true;
    else
    return false;
  },
  
  /**
  *	FUNÇÃO PARA VALIDAR CNPJ
  *	@param string cnpj ex.: 00.000.000/0000-00
  *	@return bool
  */
  
  validateCNPJ: (sCNPJ) => {
    var cnpjValor = sCNPJ;
    let cnpj = cnpjValor.replace(/[^\d]+/g, '');
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '') return false;
    if (cnpj.length != 14)
    return false;
    
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || cnpj == "11111111111111" || cnpj == "22222222222222" || cnpj == "33333333333333" || cnpj == "44444444444444" || cnpj == "55555555555555" || cnpj == "66666666666666" || cnpj == "77777777777777" || cnpj == "88888888888888" || cnpj == "99999999999999")
    return false;
    
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
      pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
    return false;
    
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
      pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
    return false;
    
    return true;
  },
  
  /**
  *   FUNÇÃO PARA VALIDAR CPF
  *   @param string cpf ex.: 000.000.000-00
  *   @return bool
  */
  
  validateCPF: (sCPF) => {
    var cpfValor = sCPF;
    let cpf = cpfValor.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos    
    if (cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999")
    return false;
    
    // Valida 1o digito 
    let add = 0;
    for (var i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
    rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
    return false;
    // Valida 2o digito 
    add = 0;
    for (i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
    rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
    return false;
    return true;
  },
  
  /**
  * FUNCAO PARA SOMAR DAYS EM DATE
  * @param date 
  * @param days 
  */
  addDays: (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },
  
  /**
   * FUNCAO PARA CALCULAR IDADE A PARTIR DA DATA DE NASCIMENTO
   * @param nascimento 
   */
  getIdade: ( nascimento: Date ): number => {
    let hoje = new Date;
    // nascimento = new Date($("#dtnascimento").val());
    var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
    if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) < new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
      diferencaAnos--;
    return diferencaAnos;
  },
  
  /**
  * FUNCAO PARA CONVERTER STRING DE PRECO EM FLOAT
  * @param preco
  */
  convPrecoToFloat: ( preco: any ): any => {
    // let aux = preco.replace(/./g, "");
    // aux = aux.replace(/,/g, ".");
    return parseFloat(preco);
  },

  captalize: (text: string) => {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  },

  /**
   *  CRIA MASCARA EM CAMPO PARA MOEDA BRL
   */
  maskPreco:  (text: string) => {
    let money = text.replace(/[^\d]/g, '');
    let tmp = money + "";
    tmp = money.replace(/([0-9]{2})$/g, ",$1");
    if( tmp.length > 6 )
      tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    return tmp;
  },

  /**
   * CRIA MASCARA EM CAMPO PARA CPF
   */
  maskCPF: () => {  
    // let cpf = text.replace(/[^\d]/g, '');
    // return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    return [/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '-',/\d/, /\d/];
  },

  /**
   * CRIA MASCARA EM CAMPO PARA CEP
   */
  maskCEP: (text: string) => {  
    let cpf = text.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
  },

  /**
   * TRATA MASCAR DO NASCIMENTO
   */
  maskNascimento:  (text: string) => {  
    let tmp = text.replace(/[^\d]/g, '');
    return tmp.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  },

  /**
   * TRATA MASCAR DO TELEFONE
   */
  maskTelefone: (text: any) => {  
    let tmp = text.replace(/[^\d]/g, '');
    let tmp2 = '';
    if ( tmp.length >= 11 )
      tmp2 = tmp.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    else
      tmp2 = tmp.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    return tmp2;
  },

  toSeoUrl: (url: any) => {
    return url.toString()               // Convert to string
      .normalize('NFD')               // Change diacritics
      .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
      .replace(/\s+/g,'-')            // Change whitespace to dashes
      .toLowerCase()                  // Change to lowercase
      .replace(/&/g,'-and-')          // Replace ampersand
      .replace(/[^a-z0-9\-]/g,'-')     // Remove anything that is not a letter, number or dash
      .replace(/-+/g,'-')             // Remove duplicate dashes
      .replace(/^-*/,'')              // Remove starting dashes
      .replace(/-*$/,'');             // Remove trailing dashes
  },

  capitalize: (s: string) => {
    if (typeof s !== 'string') return ''
    let tmp = s.toLowerCase();
    return tmp.charAt(0).toUpperCase() + tmp.slice(1)
  },

  getStatusTitulo: (status: number) => {
    switch (status) {
      case 1: return "Pendente";
      case 2: return "Em análise";
      case 3: return "Em Separação / Faturamento";
      case 4: return "Aguardando Expedição";
      case 5: return "Enviado";
      case 6: return "Entregue";
      case 7: return "Aguardando Pagamento";
      case 8: return "Cancelado";
      default: return "";
    }
  },

  getStatusFormapg: (status: number) => {
    switch (status) {
      case 1: return "Cartão de crédito";
      case 2: return "Boleto bancário";
      case 3: return "Depósito bancário";
      default: return "";
    }
  }
}