import { Reducer } from 'redux';
import { OrderState, OrderTypes } from './types';

const INITIAL_STATE: OrderState = {
  data: null,
}

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case OrderTypes.SET:
      return { 
        ...state, 
        data: {...state.data, ...action.payload.data}
      };
    
    case OrderTypes.DROP:
      return { 
        ...state,
        data: null
      };

    default:
      return state;
  }
}

export default reducer;