import React, { Component } from 'react';
import Api from '../../services/api';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


class FaleConosco extends Component <{}> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      isLoading: false,
      hasError: false,
      hasErrorMsg: null,
      hasSuccess: false,
      hasSuccessMsg: null,
      nome: null,
      email: null,
      mensagem: null,
    }
  }

  private async _handleSubmit () {
    const self = this;

    if (( !self.state.nome ) || ( !self.state.email ) || ( !self.state.mensagem )) {
      self.setState({
        hasError: true,
        hasErrorMsg: "Formulário incompleto, por favor preencha todos os campos."
      });

      return false;
    }

    let param = {
      nome: self.state.nome,
      email: self.state.email,
      fone: self.state.fone,
      mensagem: self.state.mensagem,
    }

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    try {
      const {data} = await self.api.post("/contact", param);

      if ( !data.status ) {
        throw new Error('Não foi possível enviar mensagem, por favor tente novamente.');
      } else {
        self.setState({hasSuccess: true, hasSuccessMsg: "Mensagem enviada com sucesso!"});
      }

    } catch (e) {
      console.error(e);
      self.setState({hasError: true, hasErrorMsg: "Não foi possível enviar mensagem!"});
    } finally {
      self.setState({isLoading: false});
    }
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mb-5"> <span className="heading-border"> Fale Conosco </span> </h1>
            
            {
              this.state.hasError && 
              <Row className="mt-5">
                <Col xl={12}>
                  <div className="alert alert-danger">
                    <i className="fas fa-times mr-1"></i>
                    {this.state.hasErrorMsg}
                  </div>
                </Col>
              </Row>
            }

            {
              this.state.hasSuccess && 
              <Row className="mt-5">
                <Col xl={12}>
                  <div className="alert alert-success">
                    <i className="fas fa-check mr-1"></i>
                    {this.state.hasSuccessMsg}
                  </div>
                </Col>
              </Row>
            }

            <Row className="mt-5">
              <Col xl={6}>
                <Form>
                  <Form.Group>
                    <Form.Label className="ml-3"> <strong> Seu nome </strong> </Form.Label>
                    <Form.Control type="text" placeholder="Nome" className="user-input" onChange={(event) => this.setState({nome: event.target.value})}/>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="ml-3"> <strong> Seu email </strong></Form.Label>
                    <Form.Control type="email" placeholder="E-mail" className="user-input" onChange={(event) => this.setState({email: event.target.value})}/>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="ml-3"> <strong> Sua mensagem </strong> </Form.Label>
                    <Form.Control as="textarea" placeholder="Mensagem" className="contato-textarea" onChange={(event) => this.setState({mensagem: event.target.value})}/>
                  </Form.Group>

                  <Button variant="primary" type="button" title="Enviar Mensagem" className="mt-3 btn-primary-action btn-send-message" onClick={this._handleSubmit.bind(this)} disabled={this.state.isLoading}> 
                    {
                      this.state.isLoading ? 
                      `Enviando mensagem...`
                      :
                      `Enviar` 
                    }
                  </Button>
                </Form>
              </Col>
              <Col xl={6} className="fones">
                <Row>
                  <Col lg={6} className="fones-container">
                    <div className="fones-loja">
                      <FontAwesomeIcon icon={faMapMarkerAlt}/>
                      <h3> Loja Juvevê </h3>
                    </div>
                    <span>
                      <FontAwesomeIcon icon={faPhoneAlt}/> 
                      (41) 3027-3233
                    </span>
                    <a href="https://api.whatsapp.com/send?phone=5541999985591" target="_blank" rel="noopener noreferrer" title="Enviar mensagem pelo WhatsApp (41) 9 9998-5591">
                      <FontAwesomeIcon icon={faWhatsapp}/> 
                      (41) 3027-3233
                    </a>
                  </Col>
                  <Col lg={6} className="fones-container">
                    <div className="fones-loja">
                      <FontAwesomeIcon icon={faMapMarkerAlt}/>
                      <h3> Loja Barigui </h3>
                    </div>
                    <span className="mt-4">
                      <FontAwesomeIcon icon={faPhoneAlt}/> 
                      (41) 3027-3233
                    </span>
                    <a href="https://api.whatsapp.com/send?phone=5541999985591" target="_blank" rel="noopener noreferrer" title="Enviar mensagem pelo WhatsApp (41) 9 9998-5591">
                      <FontAwesomeIcon icon={faWhatsapp}/> 
                      (41) 3027-3233
                    </a> 
                  </Col>
                  <Col lg={6} className="fone-colombo fones-container">
                    <div className="fones-loja">
                      <FontAwesomeIcon icon={faMapMarkerAlt}/>
                      <h3> Loja Colombo </h3>
                    </div>
                    <span className="mt-4">
                      <FontAwesomeIcon icon={faPhoneAlt}/> 
                      (41) 3562-9986
                    </span>
                    <a href="https://api.whatsapp.com/send?phone=5541999379910" target="_blank" rel="noopener noreferrer" title="Enviar mensagem pelo WhatsApp (41) 9 9937-9910">
                      <FontAwesomeIcon icon={faWhatsapp}/> 
                      (41) 9 9937-9910
                    </a> 
                  </Col>
                </Row>
                {/* <Row>
                  <h3> Fale conosco através das redes </h3>
                  <a href="https://www.facebook.com/lojashidrocolombo/?ref=br_rs" target="_blank"> <FontAwesomeIcon icon={faFacebookSquare}/> </a>
                  <a href="https://www.instagram.com/lojashidrocolombo/?hl=pt-br" target="_blank"> <FontAwesomeIcon icon={faInstagram}/> </a>
                </Row> */}
              </Col>
            </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default FaleConosco;
