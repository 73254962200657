import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import * as CartActions from '../../store/ducks/cart/actions';
import './index.scss';
import {Diversos} from '../../services/diversos';

// Import de componentes Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


interface OwnProps {
  item: any;
} 

type Props = OwnProps;

class DetailsItem extends Component<Props> {

  render () {
    return (
      <>
        <Row className="pedido-detalhes-produto">
          <Col xs={5} sm={4} lg={3} className="pedido-detalhes-imagem">
            {
              ((!this.props.item.FOTOS) || (this.props.item.FOTOS.length <= 0)) ? 
              <img src={`${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`} alt="Imagem de produto"/>
              :              
              <img src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${this.props.item.FOTOS[0].link}`} alt="Imagem de produto"/>              
            }
          </Col>
          <Col xs={7} sm={8} lg={9} className="pedido-detalhes-info">
            <p className="pedido-detalhes-nome"> {this.props.item.produtoDados.NOME} </p>
            <span className="d-flex align-items-center">
              <p> <strong> Quantidade: </strong> </p>
              <p className="pedido-detalhes-qtd"> {this.props.item.QTD} </p>
            </span>
            <span className="d-flex align-items-center">
              <p> <strong> Valor unitário: </strong> </p>
              <p className="pedido-detalhes-qtd"> R$ {Diversos.number_format(this.props.item.VALOR,2,",","")} </p>
            </span>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailsItem);