import React from 'react';
import './index.scss';


const BigBanner = props => (
  <>
    <div className="big-banner">
      <img
      // srcSet={`${props.imgMobile} 576w, ${props.imgSrc} 1345w`}
      // sizes="(max-width: 576px) 100%" 
      // src={props.imgSrc}
      src={window.innerWidth >= 576 ? props.imgSrc : props.imgMobile}
      alt="banner promocional"/>
    </div>
  </>
);

export default BigBanner;