import { Reducer } from 'redux';
import { UserState, UserTypes } from './types';

const INITIAL_STATE: UserState = {
  data: {
    codigo: 0,
    nome: '',
    email: '',
    cpf: '',
    status: false,
    avatar: '',
    token: null,
  },
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.DO_LOGIN:
      return { ...state, data: action.payload.data };
    case UserTypes.DO_LOGOUT:
      return { ...state, data: {
        codigo: 0,
        nome: '',
        email: '',
        cpf: '',
        status: false,
        avatar: '',
        token: null,
      }};
    default:
      return state;
  }
}

export default reducer;