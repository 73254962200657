import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Diversos} from '../../services/diversos';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'

import moment from "moment";
import 'moment/locale/pt-br';

// Import de componentes Bootstrap
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'


  interface StateProps { 
    cart?: Cart;
    param: Param;
  } 

  interface DispatchProps {
    add(cart: ProductType): void;
    update(cart: ProductType): void;
    drop(rowid: string): void;
    setParam(data: Param): void;
  }


  interface OwnProps {
    imageOver: any,
    imageNormal: any,
    item?: any;
  }

  type Props = StateProps & DispatchProps & OwnProps;

  class Product extends Component<Props> {

    state:any

    constructor(props) {
      super(props);
      this.state = {
        imgSrc: this.props.imageNormal
      }
    }

    componentDidMount() {
    }
  
    handleMouseOver() {
      this.setState({
        imgSrc: this.props.imageOver
      });
    }
    
    handleMouseOut() {
      this.setState({
        imgSrc: this.props.imageNormal
      });
    }

    private async handleAddCart (event) {
      const self = this;
      event.preventDefault();

      let preco = self.props.item.PRECO;
      let precoOriginal = self.props.item.PRECO;

      if ((self.props.item.PREPRO > 0) && (self.props.item.PREPRO < self.props.item.PRECO) && (moment(self.props.item.INIPRO) <= moment()) && (moment(self.props.item.FIMPRO) >= moment())) {
        preco = this.props.item.PREPRO;
      }

      let produParam = {
        codigo: self.props.item.CODIGO,
        nome: self.props.item.NOME,
        priceOrigin: precoOriginal,
        price: preco,
        qty: 1,
        foto: self.props.imageNormal,
        estoque: self.props.item.ESTOQUE,
        altura: 0,
        largura: 0,
        comprimento: 0,
        peso: (self.props.item.PESO) ? self.props.item.PESO : 1,
      };

      if (( self.props.item.ALTURA ) && ( self.props.item.ALTURA > 0 ))
        produParam.altura = self.props.item.ALTURA;
      else if (( self.props.item.COMPLEMENTO ) && ( self.props.item.COMPLEMENTO.ALTURA ) && ( self.props.item.COMPLEMENTO.ALTURA > 0 ))
        produParam.altura = self.props.item.COMPLEMENTO.ALTURA;

      if (( self.props.item.LARGURA ) && ( self.props.item.LARGURA > 0 ))
        produParam.largura = self.props.item.LARGURA;
      else if (( self.props.item.COMPLEMENTO ) && ( self.props.item.COMPLEMENTO.LARGURA ) && ( self.props.item.COMPLEMENTO.LARGURA > 0 ))
        produParam.largura = self.props.item.COMPLEMENTO.LARGURA;

      if (( self.props.item.COMPRIMENTO ) && ( self.props.item.COMPRIMENTO > 0 ))
        produParam.comprimento = self.props.item.COMPRIMENTO;
      else if (( self.props.item.COMPLEMENTO ) && ( self.props.item.COMPLEMENTO.COMPRIMENTO ) && ( self.props.item.COMPLEMENTO.COMPRIMENTO > 0 ))
        produParam.comprimento = self.props.item.COMPLEMENTO.COMPRIMENTO;

      self.props.add(produParam);
      self.props.setParam({ ...self.props.param, cartOpened: true });
    }

    render () {

      return (
        <>
          
        <a href={`/${Diversos.toSeoUrl(this.props.item.NOME)}`}
          target="_self"
          onMouseOver={this.handleMouseOver.bind(this)}
          onMouseOut={this.handleMouseOut.bind(this)}
          className="mb-5 product-container" title="Ver detalhes do produto">
            <Row className="w-100 img-wrapper">
              <Image src={this.state.imgSrc} className="product-img" alt=""/>
            </Row>
            <Row className="w-100 product-info">
              <Row className="product-name">
                {this.props.item.NOME}
              </Row>
              <Row className="price-and-cart">
                <Col xs={8} className="rmv-padding">
                  {
                    ((this.props.item.PREPRO > 0) && (this.props.item.PREPRO < this.props.item.PRECO) && (moment(this.props.item.INIPRO) <= moment()) && (moment(this.props.item.FIMPRO) >= moment())) ? 
                    <>
                      <p className="product-price-alt"> R$ {Diversos.number_format(this.props.item.PRECO,2,",","")} </p>
                      <p className="product-price"> R$ {Diversos.number_format(this.props.item.PREPRO,2,",","")} </p> 
                    </>
                    :
                    <p className="product-price"> R$ {Diversos.number_format(this.props.item.PRECO,2,",","")} </p>
                  }
                </Col>
                <Col xs={4} className="d-flex flex-end rmv-padding">
                  {
                    (parseFloat(this.props.item.ESTOQUE) <= 0) ? 
                    <span className="text-center" style={{width: 50}}>
                      <small> Sem estoque </small>
                    </span>
                    :
                    <FontAwesomeIcon icon={faCartPlus} onClick={this.handleAddCart.bind(this)} className="add-to-cart" title="Adicionar ao carrinho"/>
                  }
                </Col>
              </Row>
            </Row>
        </a>

        </>
      );
    }
  }

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Product);