import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import './index.scss';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';

import { Redirect } from "react-router-dom";
import Api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faReceipt, faUnlock, faEnvelope, faSyncAlt, faBarcode, faSignOutAlt, faStore } from '@fortawesome/free-solid-svg-icons';



interface StateProps { 
  user: User;
} 

interface DispatchProps {
  doLogout(): void;
}

type Props = StateProps & DispatchProps;


class Central extends Component <Props> {

  state: any = null;
  api: any = null;

  constructor (props) {
    super(props);

    this.api = new Api();

    this.state = {
      redirect: null,
      isLoadingCustomer: true,
      customer: null
    }
  }

  componentDidMount () {
    const self = this;
    
    if (( !self.props.user ) || ( self.props.user.status === false )) { 
      self.setState({redirect: '/login'});
    }

    if (( self.props.user ) && ( self.props.user.status !== false )) { 
      self.getCustomer();
    }
  }

  private async getCustomer () {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status === false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async handleLogout () {
    const self = this;
    self.props.doLogout();
    self.setState({redirect: "/"});
  }

  render () {
    
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>

        <Header/>

        <section className="central-do-cliente">
          <h1 className="mt-3"> <span className="heading-border"> Central do cliente </span> </h1>
          <h4>
            {
              this.state.customer && 
              `Olá ${this.state.customer.nome}, Seja bem vindo(a) à Central do Cliente!`
            }
          </h4>

					<Row className="p-0 mx-auto central-row">
						<Col xs={12} sm={6} lg={4} className="central-col"> 
              <a href="/meu-cadastro">                
                <Card className="m-auto central-item">
                  <Card.Body>
                    <Card.Title> Meu Cadastro </Card.Title>
                    <FontAwesomeIcon icon={faUserCircle} className="central-icon"/>
                    <small> Consulte ou altere seu dados. </small>
                  </Card.Body>
                </Card>
              </a>
            </Col>
						<Col xs={12} sm={6} lg={4} className="central-col">
              <a href="/meus-pedidos">
                <Card className="m-auto central-item">
                  <Card.Body>
                    <Card.Title> Meus Pedidos </Card.Title>
                    <FontAwesomeIcon icon={faReceipt} className="central-icon"/>
                    <small> Confira seus pedidos realizados </small>
                  </Card.Body>
                </Card>
              </a>
            </Col>
						<Col xs={12} sm={6} lg={4} className="central-col">
              <a href="/recuperar-senha">
                <Card className="m-auto central-item">
                  <Card.Body>
                    <Card.Title> Reset de Senha </Card.Title>
                    <FontAwesomeIcon icon={faUnlock} className="central-icon"/>
                    <small> Troque sua senha de acesso atual </small>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col xs={12} sm={6} lg={4} className="central-col"> 
              <a href="/fale-conosco"> 
                <Card className="m-auto central-item">
                  <Card.Body>
                    <Card.Title> Fale Conosco </Card.Title>
                    <FontAwesomeIcon icon={faEnvelope} className="central-icon"/>
                    <small> Entre em contato </small>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            {/* <Col xs={12} sm={6} lg={4} className="central-col"> 
              <a href="/">
                <Card className="m-auto central-item central-item-disabled">
                  <Card.Body>
                    <Card.Title> 2ª via do boleto </Card.Title>
                    <FontAwesomeIcon icon={faBarcode} className="central-icon"/>
                    <small> Reimprima a segunda via do seu boleto </small>
                  </Card.Body>
                </Card>
              </a>
            </Col> */}
            <Col xs={12} sm={6} lg={4} className="central-col"> 
              <a href="/lojas">
                <Card className="m-auto central-item central-item-disabled">
                  <Card.Body>
                    <Card.Title> Nossas Lojas </Card.Title>
                    <FontAwesomeIcon icon={faStore} className="central-icon"/>
                    <small> Venha nos fazer uma visita! </small>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col xs={12} sm={6} lg={4} className="central-col"> 
              <a href="/trocas-e-devolucoes">
                <Card className="m-auto central-item">
                  <Card.Body>
                    <Card.Title> Trocas e Devoluções </Card.Title>
                    <FontAwesomeIcon icon={faSyncAlt} className="central-icon"/>
                    <small> Efetuar troca ou devolução de compra </small>
                  </Card.Body>
                </Card>
              </a>
            </Col>
					</Row>

          <div className="central-bottom-links">
            <span>  
              <h6> Links Úteis: </h6>
              <a href="/trocas-e-devolucoes"> Política de Trocas e Devoluções </a>
              <a href="/frete-e-envio"> Frete e Envio </a>
            </span>
            <button className="central-logout" onClick={this.handleLogout.bind(this)}> <FontAwesomeIcon icon={faSignOutAlt}/> Fazer Logout </button>
          </div>

        </section>

        <Footer/>

      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Central);
