import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import logoFooter from '../../assets/Images/logo-footer.png'
import logoTecworks from '../../assets/Images/tecworks.png'

// Import de Componentes Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const Footer: React.FC = () => {
  return (
    <>
      <footer>
        <Row className="w-100 logo-container">
          <img src={logoFooter} style={{maxWidth: '100%'}} alt="Logo Hidrocolombo"/> 
        </Row>
        <Row className="w-100 mb-5 footer-content">
          <Col lg={6} xl={6} className="stores">
            <Row className="title-row"> <h4> Lojas Hidrocolombo </h4> </Row> 
            <Row>
              <Col md={5} className="info">
                <p> Loja Juvevê </p>
                Avenida João Gualberto, 1612. Em frente ao BIG Juvevê
                <span className="mt-4">
                  <FontAwesomeIcon icon={faPhoneAlt}/> 
                  (41) 3027-3233
                </span>
                <span>
                  <FontAwesomeIcon icon={faWhatsapp}/> 
                  (41) 3027-3233
                </span> 
              </Col>
              <Col md={5} className="info">
                <p> Loja Barigui </p>
                R. Padre Anchieta, 2618 - 04
                <span className="mt-4">
                  <FontAwesomeIcon icon={faPhoneAlt}/> 
                  (41) 3022-8283
                </span>
                <span>
                  <FontAwesomeIcon icon={faWhatsapp}/> 
                  (41) 3022-8283
                </span> 
              </Col>
            </Row>
            <Row className="info-row">
              <Col md={5} className="info">
                <p> Loja Colombo - Centro de Distribuição </p>
                R. José Belém, 244 - Roça Grande, Colombo - PR
                <span className="mt-4">
                  <FontAwesomeIcon icon={faPhoneAlt}/> 
                  (41) 3562-9986
                </span>
                <span>
                  <FontAwesomeIcon icon={faWhatsapp}/> 
                  (41) 9 9937-9910
                </span> 
              </Col>
            </Row>
          </Col>
          <Col lg={3} xl={3} className="social">
            <Row className="title-row"> <h4> Midias Sociais </h4> </Row>
            <Row className="title-row">
              <a href="https://www.facebook.com/lojashidrocolombo/?ref=br_rs" target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faFacebookSquare}/> </a>
              <a href="https://www.instagram.com/lojashidrocolombo/?hl=pt-br" target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faInstagram}/> </a>
            </Row>
          </Col>
          <Col lg={3} xl={3} className="social">
            <Row className="title-row"> <h4> Nagevação </h4> </Row>
            <Row className="navigation">
              <a href="/central-do-cliente"> Central do Cliente </a>
              <a href="/meu-cadastro"> Minha conta </a>
              <a href="/meus-pedidos"> Meus Pedidos </a>
              <a href="/hidrocolombo"> A Hidrocolombo </a>
              <a href="/lojas"> Lojas </a>
              <a href="/fale-conosco"> Fale Conosco </a>
              <a href="/trocas-e-devolucoes"> Política de Trocas e Devoluções </a>
              <a href="/frete-e-envio"> Frete e Envio </a>
            </Row>
          </Col>
        </Row>
        <Row className="w-100 mt-5 copyright-container">
          <p className="copyright"> Todos os direitos reservados © 2020 - Hidrocolombo - Ponto da Casa Com. de Materiais E.H.U.
          Ltda - CNPJ 07.504.037/0004-49 </p>
          <p className="copyright"> Desenvolvido por Grupo TecWorks <img src={logoTecworks} alt="logo Tecworks"/> </p>
        </Row>
      </footer>
    </>
  );
}

export default Footer