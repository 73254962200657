/**
 * ACTIONS TYPES
 */
export enum OrderTypes {
  SET = '@repositories/SET',
  DROP = '@repositories/DROP'
}

export interface ItemType {
  produto: number
  qtd: number
  valor: number
  nome?:string
}

export interface OrderType {
  cliente: any
  loja?: number
  data: string
  status: number
  desconto?: number
  repres?: number
  oper?: number
  entrega: string
  dtentrega: string
  frete: number
  tipoped?: number
  formapg: number
  hora: string
  pedido: number
  autorizacao?: string
  nsu?: string
  tid?: string
  gateway?: string
  ps_code?: string
  ps_date?: string
  ps_rate_amount?: number
  ps_fee_amount?: number
  ps_gateway?: string
  boleto_url: string
  itens: Array<ItemType>
  cartao?:any
}

/**
 * State types
 */
export interface OrderState {
  readonly data: OrderType | null
}