import React, { Component } from 'react';
import './index.scss';
import { OrderType } from '../../store/ducks/order/types';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt, faExclamation, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';


interface Props { 
  order: OrderType;
} 


class Central extends Component <Props> {

  state: any = {};

  constructor (props: any) {
    super(props);

    this.state = {
      pedido: this.props.order
    }
  }

  render () {
    return (
      <>

        <Header/>

        <section className="trocas-e-devolucoes">
          <h1 className="mt-3"> <span className="heading-border"> Política de Trocas e Devoluções </span> </h1>
          <article className="canais-atendimento-container">
            <p>
              A Hidrocolombo preza pelo atendimento a seus clientes, e por isso, trazemos definições para facilitar o entendimento em relação às
              Trocas e Devoluções de produtos comprados em nossa loja online. O processo de troca e devolução deve ser solicitado
              através dos seguintes canais de atendimento:
            </p>
            <Row className="mt-4">
              <Col sm={6}>
                <span className="canal-atendimento"> <FontAwesomeIcon icon={faEnvelope}/> E-mail </span>
                <a href="sac@hidrocolombo.com.br"> sac@hidrocolombo.com.br </a>
              </Col>
              <Col sm={6}>
                <span className="canal-atendimento"> <FontAwesomeIcon icon={faPhoneAlt}/> Telefone/Whatsapp </span>
                <a href="(41) 3562-9986"> (41) 3562-9986 </a>
              </Col>
            </Row>
          </article>
          
          <hr/>

          <Row>
            <Col lg={6}>
              <article className="compra-container">
                <h2> Compra </h2>
                <p> No momento da <strong> compra </strong>, é importante se atentar: </p>
                <ul className="warn-list">
                  <li> Descrição e ficha técnica do produto </li>
                  <li> Voltagem do produto adquirido </li>
                  <li> Cor do produto  </li>
                  <li> Metragem e textura onde o produto será aplicado </li>
                  <li> Dimensões do produto (verificar dimensões do local onde o produto será instalado/montado) </li>
                </ul>
              </article>
            </Col>
            <Col lg={6}>
              <article>
                <h2> Recebimento </h2>
                <p> Para o momento do <strong> recebimento </strong>, é importante estar atento à conferência do pedido,
                devendo <strong> recusar </strong> o recebimento do produto em casos de avarias ou embalagens violadas. </p>
              </article>
            </Col>
          </Row>

          <hr/>

          <article className="trocas-container">
            <h2> Trocas/Devoluções </h2>
            <p> 
              Com a finalidade de oferecer a máxima satisfação aos nossos clientes e seguindo o Código de Defesa do Consumidor
              (disponível <a href="http://goo.gl/4g0EO"> neste link </a>), a Hidrocolombo dispõe do serviço de Trocas e Devoluções. 
            </p>
            <ul className="regras-trocas">
              <li> Em caso de arrependimento a troca deve ser solicitada até 07 (sete) dias do recebimento do produto, com a apresentação da respectiva Nota Fiscal. </li>
              <li> Em caso de defeito de fabricação, o prazo de troca é de 30 (trinta) dias, a partir do recebimento do produto, com a apresentação da respectiva Nota Fiscal. </li>
              <li> Produtos comprados por engano, cor ou dimensões erradas, etc, a Hidrocolombo não se responsabiliza por qualquer custo adicional referente ao frete de retorno e à nova entrega.  </li>
            </ul>
          </article>

          <hr/>

          <article className="situacao-container">
            <h2> Situação dos produtos </h2>
            <strong className="success"> <FontAwesomeIcon icon={faCheck}/> SERÃO ACEITOS: </strong>
            <ul className="warn-list">
              <li> Mercadorias acompanhadas da nota fiscal ou cupom fiscal, emitidos pela Hidrocolombo </li>
              <li> Embalagem original, lacrada, com todos os acessórios, manual de instrução e que não apresente sinais de uso, ou danificadas por má instalação ou manuseio inadequado. Os produtos das categorias de Eletrônicos, Informática e Telefonia, devem estar com a etiqueta interna (lacre de garantia) original do fabricante intacta; </li>
              <li> Em condições de revenda. </li>
            </ul>
            <big> <FontAwesomeIcon icon={faExclamation}/> IMPORTANTE </big>
            <p>
              A Hidrocolombo fará uma auditoria no produto recebido pra validar a troca/devolução, e não aceitará a devolução de produto que o defeito tenha ocorrido por mau uso
              por parte do cliente ou em casos de reclamações efetuadas fora dos prazos estabelecidos. Se o produto analisado não estiver de acordo com as regras citadas acima,
              o mesmo poderá ser devolvido ao Cliente sem aviso prévio.
            </p>
            <strong className="danger"> <FontAwesomeIcon icon={faTimes}/> NÃO SERÃO ACEITOS: </strong>
            <ul className="warn-list">
              <li> Produtos que tenham sido usados (exceto para os casos de vício ou defeito técnico ou de fabricação, constatado após utilização). </li>
              <li> Produtos com defeito ou vício de fabricação, sem cobertura de Assistência Técnica, serão aceitos pela Hidrocolombo em até 30 dias corridos contados a partir da data do recebimento do produto. </li>
              <li> Produtos vendidos com código de Pontas-de-estoque e Saldo </li>
              <li> Produtos sob encomenda </li>
            </ul>
          </article>

          <hr/>

          <article className="situacao-container">
            <h2> Garantias </h2>
            <p>
              Produtos com defeitos ou vícios de fabricação, apresentados dentro do prazo de garantia, procure os serviços de assistência técnica.
              É importante que você guarde sua Nota Fiscal ou Danfe para a preservação dos seus direitos. 
            </p>
          </article>

          <hr/>


          <article className="situacao-container">
            <h2> Reembolso </h2>
            <p>
              A devolução dos valores será realizada após a devolução do produto e iremos realizar o estorno do valor pago de acordo com a forma de pagamento escolhida no momento da compra:
            </p>
            <strong> PAGAMENTO COM CARTÃO DE CRÉDITO: </strong>
            <p>  
              Os prazos de estorno dos valores dependem exclusivamente da administradora de cartão de crédito e podem ocorrer em até 2 (duas) faturas posteriores.
            </p>
            <strong> PAGAMENTO COM BOLETO BANCÁRIO: </strong>
            <p>  
              A devolução será efetuada por meio de depósito bancário, em até 10 (dez) dias úteis, a contar do recebimento do produto e das informações necessárias. O CPF do titular da conta para devolução deverá ser o mesmo CPF do titular da compra.
            </p>
          </article>

        </section>

        <Footer/>

      </>
    );
  }
}

export default Central;
