import React, { Component } from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import moment from "moment";
import 'moment/locale/pt-br';
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';

// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Product from '../../components/Product';
import SmallBanner from '../../components/SmallBanner';
import BigBanner from '../../components/BigBanner';

// import de Imagens/SVG
import Delivery from '../../assets/Images/delivery.png'
import Cellphone from '../../assets/Images/cellphone.png'
import Clipboard from '../../assets/Images/clipboard.png'
import Discount from '../../assets/Images/discount.png'
import bannerBig from '../../assets/Images/banner-big.png'
import Bathtub from '../../assets/Images/bathtub.png'
import Handsaw from '../../assets/Images/handsaw.png'
import Kitchen from '../../assets/Images/kitchen.png'
import Trowel from '../../assets/Images/trowel.png'
import Tubes from '../../assets/Images/tubes.png'
import WalkieTalkie from '../../assets/Images/walkietalkie.png'
import Minibanner1 from '../../assets/Images/minibanner-1.png'
import Minibanner2 from '../../assets/Images/minibanner-2.png'
import SuperBanner from '../../assets/Images/banner-promocao.png'
import SuperBannerMobile from '../../assets/Images/large-banner.png'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'

declare var window: any;

interface StateProps { 
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

class Home extends Component<StateProps & DispatchProps & OwnProps> {

  api: any = null;
  state: any = null;

  constructor (props) {
    super(props);
    this.api = new Api();
    this.state = {
      
      // State de produtos destaques do mês
      destaqueHomeLimit: 1,
      destaqueHomeIsLoading: true,
      destaqueHomeProcuts: [],

      // State de produtos em promoção
      promocaoHomeLimit: 1,
      promocaoHomeIsLoading: true,
      promocaoHomeProcuts: [],
      
      // State de produtos mais vendidos
      maisVendidosIsLoading: false,
      maisVendidosProducts: [],

      isLoadingBanner: true,
      banners: []
    };
  }

  componentDidMount () {
    this.getDestaqueHome();
    this.getPromocaoHome();
    this.getMaisVendidosHome();
    this.getBannersHome();
  }

  private _henderSEOTags () {
    return (
      <Helmet>
        <title>{process.env.REACT_APP_TITLE} soluções em materiais elétricos, hidráulicos e construção</title>
        <link rel="canonical" href={process.env.REACT_APP_URL} />
        <meta name="url" content={process.env.REACT_APP_URL} />
        <meta name="robots" content="index"/>
        <meta name="description" content={`Promoções exclusivas em materiais elétricos, hidráulicos e construção somente em nossa loja online - ${process.env.REACT_APP_TITLE}`} />
        <meta name="keywords" content={`materiais elétricos, materiais hidráulicos, materiais de construção, acabamentos, ferramentas, tintas, iluminação, compre online, compre e receba, compre sem sair de casa, recebe em casa, loja online, ecommerce`} />
        <meta name="autor" content="TecWorks" />
        <meta name="company" content="TecWorks" />
        <meta name="revisit-after" content="2" />

        {/* LD+JSON  */}
        <script type="application/ld+json">
          {`
            [{
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "${process.env.REACT_APP_TITLE}",
                "alternateName": "Hidrocolombo",
                "url": "${process.env.REACT_APP_URL}",
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": "${process.env.REACT_APP_URL}/busca/{search_term_string}",
                    "query-input": "required name=search_term_string" 
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                "url": "${process.env.REACT_APP_URL}",
                "logo": "${require(`../../assets/Images/logo.png`)}",
                "contactPoint": [{
                    "@type": "ContactPoint",
                    "telephone": "+55 (41) 3027-3233",
                    "contactType": "sales"
                }]
            },
            {
                "@context": "http://schema.org",
                "@type": "Person",
                "name": "${process.env.REACT_APP_TITLE}",
                "url": "${process.env.REACT_APP_URL}",
                "sameAs": [
                    "https://www.facebook.com/hidrocolombo/",
                    "https://www.instagram.com/lojashidrocolombo/"
                ]
            }]
          `}
        </script>

        {/* TWITTER CARDS */}
        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content={`${process.env.REACT_APP_URL}`} />
        <meta name="twitter:title" content={`${process.env.REACT_APP_TITLE}`} />
        <meta name="twitter:description" content={`Promoções exclusivas para nossa loja virtual - ${process.env.REACT_APP_TITLE}`} />
        <meta name="twitter:image" content={require(`../../assets/Images/logo.png`)} />

        {/* FACEBOOK CARDS */}
        <meta property="fb:app_id" content="270229570743012" />
        <meta property="og:site_name" content={`${process.env.REACT_APP_TITLE}`}/>
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={`${process.env.REACT_APP_URL}`}/>
        <meta property="og:title" content={`${process.env.REACT_APP_TITLE}`}/>
        <meta property="og:image" content={require(`../../assets/Images/logo.png`)} />
        <meta property="og:description" content={`Promoções exclusivas para nossa loja virtual - ${process.env.REACT_APP_TITLE}`}/>
        {/* <meta property="og:product:price:amount" content="'. $facebook->product_price_amount .'">
        <meta property="og:product:price:currency" content="'. $facebook->product_price_currency .'">
        <meta property="og:product:brand" content="'. $facebook->product_brand .'">
        <meta property="og:product:condition" content="'. $facebook->product_condition .'">
        <meta property="og:product:availability" content="'. $facebook->product_availability .'"> */}
      </Helmet>
    );
  }

  private async getDestaqueHome () {
    const self = this;
    
    self.setState({destaqueHomeIsLoading: true});

    let param = {};

    try {
      const {data} = await self.api.post(`/product/home/${self.state.destaqueHomeLimit}`, param);

      if ( data.status === false ) {
        throw new Error(`Nenhum produto localizado na home: ${data.msg}`);
      } else {
        self.setState({destaqueHomeProcuts: data.msg.data})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({destaqueHomeIsLoading: false});
    }
  } 

  private async getPromocaoHome () {
    const self = this;
    
    self.setState({promocaoHomeIsLoading: true});

    let param = {};

    try {
      const {data} = await self.api.post(`/product/promocao/${self.state.promocaoHomeLimit}`, param);

      if ( data.status === false ) {
        throw new Error(`Nenhum produto localizado na promocao: ${data.msg}`);
      } else {
        self.setState({promocaoHomeProcuts: data.msg.data})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({promocaoHomeIsLoading: false});
    }
  } 

  private async getMaisVendidosHome () {
    const self = this;

    self.setState({maisVendidosIsLoading: true});

    let param = {};

    try {
      const {data} = await self.api.post(`/product/promocao/${self.state.maisVendidosLimit}`, param);

      if ( data.status === false ) {
        throw new Error(`Nenhum produto localizado nos mais vendidos: ${data.msg}`);
      } else {
        self.setState({maisVendidosProducts: data.msg.data})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({maisVendidosIsLoading: false});
    }
  }

  private async getBannersHome () {
    const self = this;
    
    self.setState({isLoadingBanner: true});

    try {
      const {data} = await self.api.get(`/banners?tipo=1`);

      if ( data.status === false ) {
        throw new Error(`Nenhum banner localizado: ${data.msg}`);
      } else {
        self.setState({banners: data.msg})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({isLoadingBanner: false});
    }
  } 

  render () {

    const vitrineBp = [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]

    const smDevice = window.innerWidth <= 576;

    return (
      <>
        {this._henderSEOTags()}
        <Header/>

        <section className="banner-slider">
          {
            this.state.isLoadingBanner === true ?
            <div className="row">
              <div className="col-12 text-center">
                <i className="fas fa-spin fa-spinner"></i>
              </div>
            </div>
            :
            this.state.banners.length <= 0 ?
            <></>
            :
            <Carousel indicators={false}>
              {
                this.state.banners.map((row, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={`http://painel.hidrocolombo.com.br/${row.path}`}
                      alt={row.titulo}
                    />
                  </Carousel.Item>
                ))
              }
            </Carousel>
          }
        </section>

        <section className="informative-container">
          <Slider
          dots={false}
          arrows={false}
          autoplay={true}
          infinite={true}
          speed={500}
          slidesToShow={smDevice ? 1 : 4}
          slidesToScroll={1}
          responsive={vitrineBp} >
            <div className="info-item">
              <img src={Delivery} alt=""/>
              <p> ENTREGA A DOMICÍLO </p>
              Suas compras entregues à você com rapidez e muito cuidado
            </div>
            <div className="info-item">
              <img src={Cellphone} alt=""/>
              <p> COMPRE SEM SAIR DE CASA </p>
              Com agilidade e praticidade
            </div>
            <div className="info-item">
              <img src={Clipboard} alt=""/>
              <p> ORÇAMENTO SEM COMPROMISSO </p>
              Faça seu orçamento conosco!
            </div>
            <div className="info-item">
              <img src={Discount} alt=""/>
              <p> OFERTAS MENSAIS </p>
              Aproveite nossas seleções de ofertas
            </div>
          </Slider>
        </section>

        {/* PRODUTOS EM DESTAQUE DO MES  */}
        {
          ((this.state.destaqueHomeIsLoading === true) || (this.state.destaqueHomeProcuts.length > 0)) &&
          <section className="mt-0 products-display">
            <h2> <span className="heading-border"> Destaques </span> do mês de {moment().format('MMMM')} </h2>
            {
              this.state.destaqueHomeIsLoading ?
              <Row>
                <Col xs={12} className="text-center py-0 px-0">
                  <i className="fas fa-spin fa-spinner fa-3x my-3 mx-auto"></i>
                </Col>
              </Row>
              :
              <Row>
                <Col xs={12} className="my-5 carousel-products">
                  <Slider
                  dots={false}
                  arrows={true}
                  autoplay={true}
                  infinite={true}
                  speed={500}
                  slidesToShow={5}
                  slidesToScroll={1}
                  responsive={vitrineBp} >
                  {
                    this.state.destaqueHomeProcuts.map((row,index) => (
                      <Product 
                        imageNormal={
                          ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                          : 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        }
                        imageOver={
                          ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                            (row.FOTOS.length > 1) ? 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                            : 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                          :
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        } 
                        item={row}
                      />
                    ))
                  }
                  </Slider>
                </Col>
              </Row>
            }
          </section>
        }

        <section className="mini-banners">
          <a href="/promocoes" title="Ver produtos de reforma em promoção" target="_self" style={{width: '100%'}}>
            <BigBanner imgSrc={SuperBanner} imgMobile={SuperBannerMobile}/>
          </a>
          {/* <a href="/departamento/comunicacao-e-seguranca" title="Ver produtos de Comunicação e Segurança" target="_self" style={{width: '48%'}}>
            <SmallBanner imgSrc={Minibanner1}/>
          </a>
          <a href="/departamento/banheiro" title="Ver produtos de Banheiro" target="_self" style={{width: '48%'}}>
            <SmallBanner imgSrc={Minibanner2}/>
          </a> */}
        </section>

        {/* PRODUTOS EM PROMOCAO DO MES  */}
        {
          ((this.state.promocaoHomeIsLoading === true) || (this.state.promocaoHomeProcuts.length > 0)) &&
          <section className="mt-5 products-display">
            <h2> <span className="heading-border"> Promoções </span> do mês de {moment().format('MMMM')} </h2>
            {
              this.state.promocaoHomeIsLoading ?
              <Row>
                <Col xs={12} className="text-center py-0 px-0">
                  <i className="fas fa-spin fa-spinner fa-3x mx-auto"></i>
                </Col>
              </Row>
              :
              <Row>
                <Col xs={12} className="my-5 carousel-products">
                  <Slider
                  dots={false}
                  arrows={true}
                  autoplay={true}
                  infinite={true}
                  speed={500}
                  slidesToShow={5}
                  slidesToScroll={1}
                  responsive={vitrineBp} >
                  {
                    this.state.promocaoHomeProcuts.map((row,index) => (
                      <Product 
                        imageNormal={
                          ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                          : 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        }
                        imageOver={
                          ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                            (row.FOTOS.length > 1) ? 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                            : 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                          :
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        } 
                        item={row}
                      />
                    ))
                  }
                  </Slider>
                </Col>
              </Row>
            }
          </section>
        }
    
        <section className="mt-5 mb-5 products-display">
          <h2> <span className="heading-border"> Categorias </span> </h2>
          <Row className="mt-5 mb-5 justify-content-center">
            <Col xs={6} sm={6} md={4} lg={3} xl={1} className="categories-item">
              <a href="/departamento/banheiro" title="Ver produtos de Banheiro" target="_self" className="categories-id">
                <img className="categories-icon" src={Bathtub} alt="Categoria"/>
                Banheiros
              </a>
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={1} className="categories-item">
            <a href="/departamento/comunicacao-e-seguranca" title="Ver produtos de Comunicação e Segurança" target="_self" className="categories-id">
              <img className="categories-icon" src={WalkieTalkie} alt="Categoria" />
              Segurança & Comunicação
            </a>
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={1} className="categories-item">
            <a href="/departamento/cozinha-e-area-de-servicos" title="Ver produtos de Cozinha e Área de serviços" target="_self" className="categories-id">
              <img className="categories-icon" src={Kitchen} alt="Categoria" />
              Cozinha & Área de Serviços
            </a>
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={1} className="categories-item">
            <a href="/departamento/ferramentas" title="Ver produtos de Ferramentas" target="_self" className="categories-id">
              <img className="categories-icon" src={Handsaw} alt="Categoria" />
              Ferramentas
            </a>
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={1} className="categories-item">
            <a href="/departamento/ferragens" title="Ver produtos de Ferragens" target="_self" className="categories-id">
              <img className="categories-icon" src={Tubes} alt="Categoria" />
              Ferragens
            </a>
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={1} className="categories-item">
            <a href="/departamento/jardim" title="Ver produtos de Jardim" target="_self" className="categories-id">
              <img className="categories-icon" src={Trowel} alt="Categoria" />
              Jardim
            </a>
            </Col>
          </Row>
        </section>

        {/* PRODUTOS MAIS VENDIDOS  */}
        {
          ((this.state.maisVendidosIsLoading === true) || (this.state.maisVendidosProducts.length > 0)) &&
          <section className="mt-5 products-display" style={{marginBottom: '3rem'}}>
            <h2> <span className="heading-border"> Produtos </span> mais vendidos </h2>
            {
              this.state.maisVendidosIsLoading ?
              <Row>
                <Col xs={12} className="text-center py-0 px-0">
                  <i className="fas fa-spin fa-spinner fa-3x mx-auto"></i>
                </Col>
              </Row>
              :
              <Row>
                <Col xs={12} className="my-5 carousel-products">
                  <Slider
                  dots={false}
                  arrows={true}
                  autoplay={true}
                  infinite={true}
                  speed={500}
                  slidesToShow={5}
                  slidesToScroll={1}
                  responsive={vitrineBp} >
                  {
                    this.state.maisVendidosProducts.map((row,index) => (
                      <Product 
                        imageNormal={
                          ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                          : 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        }
                        imageOver={
                          ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                            (row.FOTOS.length > 1) ? 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                            : 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                          :
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        } 
                        item={row}
                      />
                    ))
                  }
                  </Slider>
                </Col>
              </Row>
            }
          </section>
        }

        <section className="mt-5 mb-5 products-display">
          <h2> <span className="heading-border"> Vantagens </span> de ser cliente Hidrocolombo </h2>
          <Container className="perks-container">
            <Row>
              <Col md={4} lg={4} className="perks-wrapper">
                <h2 className="perks-number"> 1 </h2>              
                <h3 className="perks secondary"> Orçamentos sem compromisso </h3>
                <p className="perks secondary"> Faça seu orçamento conosco em nossas lojas, site ou através do Whatsapp! </p>
              </Col>

              <Col md={4} lg={4} className="perks-wrapper" style={{backgroundColor: '#243352'}}>
                <h2 className="perks-number perks"> 2 </h2>
                <h3 className="perks"> Opte por pagar somente quando receber o produto </h3>
                <p className="perks"> Ao realizar suas compras pelo site, você pode optar por pagar quando receber os produtos em casa! </p>
              </Col>

              <Col md={4} lg={4} className="perks-wrapper">
                <h2 className="perks-number"> 3 </h2>
                <h3 className="perks secondary"> Com o Cartão Fidelidade, você concorre a super prêmios </h3>
                <p className="perks secondary"> Obtenha nosso Cartão Fidelidade e a cada compra, preenchendo o cartão, você concore a diversos prêmios!</p>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer/>

      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
