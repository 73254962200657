import React, { Component } from 'react';
import './App.scss';

import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps, Switch, Redirect } from "react-router-dom";
import {store, persistor} from '../store';

import Home from "./Home";
import Produto from "./Produto";
import ResetSenha from "./ResetSenha";
import Login from "./Login";
import Cadastro from "./Cadastro";
import MeuCadastro from "./MeuCadastro";
import MeusPedidos from "./MeusPedidos";
import Central from "./Central";
import TrocasDevolucoes from "./TrocasDevolucoes";
import FreteEnvio from "./FreteEnvio";
import Departamento from "./Departamento";
import Promocao from "./Promocao";
import Hidrocolombo from "./Hidrocolombo";
import Lojas from "./Lojas";
import FaleConosco from "./FaleConosco";
import OfertasDoMes from "./OfertasDoMes";
import Pesquisa from "./Pesquisa";
import Checkout from "./Checkout";
import CheckoutPagamento from "./CheckoutPagamento";
import CheckoutFim from "./CheckoutFim";
import NoMatchPage from "./NoMatchPage";

class App extends Component <{}> {

  render () {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>

            <Switch>
              {/* DECLARA AS ROTAS USADAS NO PROJETO */}
              <Route path="/" exact component={Home}/>
              <Route path="/login" exact component={Login}/>
              <Route path="/cadastro" exact component={Cadastro}/>
              <Route path="/meu-cadastro" exact component={MeuCadastro}/>
              <Route path="/central-do-cliente" exact component={Central}/>
              <Route path="/meus-pedidos" exact component={MeusPedidos}/>
              <Route path="/trocas-e-devolucoes" exact component={TrocasDevolucoes}/>
              <Route path="/frete-e-envio" exact component={FreteEnvio}/>
              <Route path="/recuperar-senha" exact component={ResetSenha}/>
              <Route path="/departamento/:menu1" exact strict component={Departamento}/>
              <Route path="/departamento/:menu1/:menu2" exact strict component={Departamento}/>
              <Route path="/departamento/:menu1/:menu2/:menu3" exact strict component={Departamento}/>
              <Route path="/busca/:termo" exact strict component={Pesquisa}/>
              <Route path="/promocoes" exact strict component={Promocao}/>
              <Route path="/hidrocolombo" exact strict component={Hidrocolombo}/>
              <Route path="/lojas" exact strict component={Lojas}/>
              <Route path="/fale-conosco" exact strict component={FaleConosco}/>
              <Route path="/ofertas-do-mes" exact strict component={OfertasDoMes}/>
              <Route path="/checkout" exact strict component={Checkout}/>
              <Route path="/checkout/entrega" exact strict component={CheckoutPagamento}/>
              <Route path="/checkout/fim" exact strict component={CheckoutFim}/>
              <Route path="/404" exact stric component={NoMatchPage}/>
              <Route path="/:nome" exact component={Produto}/>
            </Switch>
            
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
