import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Helmet} from "react-helmet";
import Shimmer from '@bit/aju.main.shimmer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import './index.scss';
import InputMask from 'react-input-mask'
import Slider from "react-slick";
import Lottie from 'react-lottie';
import Loading from '../../assets/animations/loading.json'

import moment from "moment";
import 'moment/locale/pt-br';

// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Product from '../../components/Product';
import SemEstoque from '../../components/SemEstoque';

import logo from '../../assets/Images/logo.png'


// import de Componentes do Bootstrap
import { Col, Row, Modal, Carousel, Form, Button, Breadcrumb, Container, InputGroup } from 'react-bootstrap'

interface StateProps { 
  cart?: Cart;
  param: Param;
} 

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

// interface OwnProps {
//   imageOver: any,
//   imageNormal: any,
// } 

type Props = StateProps & DispatchProps;
 
class Produto extends Component<Props> {

  api: any = null;
  state: any = null;
  static: any

  constructor (props) {
    super(props);
    this.api = new Api();
    const {nome} = props.match.params;
    this.state = {
      paramNome: nome,
      isLoading: true,
      produto: false,
      quantity: 1,
      menu1: null,
      menu2: null,
      menu3: null,
      estoqueModal: null,

      freteCep: null,
      freteModal: null,
      freteHasError: null,
      freteHasErrorMsg: null,
      freteData: null,
      freteIsLoading: false,

      similarIsLoading: true,
      similarData: null,
      similarHasError: false,
      similarHasErrorMsg: null
    }
  }

  async componentDidMount () {
    const self = this;

    if ( !self.state.paramNome ) {
      window.location.href = "/404";
      return;
    }

    self.setState({isLoading: true});

    try {
      const {data} = await self.api.get(`/product/${self.state.paramNome}/filter/name`);

      if ( !data.status ) {
        throw new Error('Produto não localizado');
      } else {
        self.setState({produto: data.msg});
        
        if (( data.msg.COMPLEMENTO ) && ( data.msg.COMPLEMENTO.MENU1 )) {
          const response = await self.api.get(`/menu/nivel1/${data.msg.COMPLEMENTO.MENU1}`);

          if ( response.data.status === true ) {
            self.setState({menu1: response.data.msg});
            self.getSimilares();
          }
        }

        if (( data.msg.COMPLEMENTO ) && ( data.msg.COMPLEMENTO.MENU2 )) {
          const response2 = await self.api.get(`/menu/nivel1/${data.msg.COMPLEMENTO.MENU2}`);

          if ( response2.data.status === true )
            self.setState({menu2: response2.data.msg});
        }

        if (( data.msg.COMPLEMENTO ) && ( data.msg.COMPLEMENTO.MENU3 )) {
          const response3 = await self.api.get(`/menu/nivel1/${data.msg.COMPLEMENTO.MENU3}`);

          if ( response3.data.status === true )
            self.setState({menu3: response3.data.msg});
        }
      }

    } catch (e) {
      console.error(e);
      window.location.href = "/404";
    } finally {
      self.setState({isLoading: false});
    }
  }

  private async handleAddCart (event) {
    const self = this;
    event.preventDefault();

    if (this.state.quantity <= 0) {
      return window.alert("Selecione ao menos 1 item para concluir a compra");
    }

    else {

      let preco = self.state.produto.PRECO;
      let precoOriginal = self.state.produto.PRECO;

      if ((self.state.produto.PREPRO > 0) && (self.state.produto.PREPRO < self.state.produto.PRECO) && (moment(self.state.produto.INIPRO) <= moment()) && (moment(self.state.produto.FIMPRO) >= moment())) {
        preco = self.state.produto.PREPRO;
      }

      // if (self.state.produto.CODIGO )
      self.props.add({
        codigo: self.state.produto.CODIGO,
        nome: self.state.produto.NOME,
        priceOrigin: precoOriginal,
        price: preco,
        qty: this.state.quantity,
        estoque: this.state.produto.ESTOQUE,
        foto: ((!this.state.produto.FOTOS) || (!this.state.produto.FOTOS[0])) ? `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png` : `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.produto.FOTOS[0].link}`,
        altura: ((this.state.produto.COMPLEMENTO) && (this.state.produto.COMPLEMENTO.ALTURA)) ? this.state.produto.COMPLEMENTO.ALTURA : 1,
        largura: ((this.state.produto.COMPLEMENTO) && (this.state.produto.COMPLEMENTO.LARGURA)) ? this.state.produto.COMPLEMENTO.LARGURA : 1,
        comprimento: ((this.state.produto.COMPLEMENTO) && (this.state.produto.COMPLEMENTO.COMPRIMENTO)) ? this.state.produto.COMPLEMENTO.COMPRIMENTO : 1,
        peso: (this.state.produto.PESO) ? this.state.produto.PESO : 1,
      });
      self.props.setParam({ ...self.props.param, cartOpened: true });
    }
  }

  private IncreaseItem = () => {
    this.setState(prevState => {
      if (this.state.quantity < this.state.produto.ESTOQUE) {
        return {
          quantity: this.state.quantity + 1
        }
      }

      else {
        return null;
      }
    });
  }

  private DecreaseItem = () => {
    this.setState(prevState => {
      if (this.state.quantity > 1) {
        return {
          quantity: this.state.quantity - 1
        }
      }

      else {
        return null;
      }
    });
  }

  handleChange = (event) => {
    this.setState({quantity: event.target.value});
  }

  private async handleSimularFrete () {
    const self = this;
    self.setState({
      freteModal: true,
      freteIsLoading: true,
    });
    self.getShippingModes();
  }

  private async getShippingModes () {
    const self = this;

    self.setState({freteIsLoading: true});

    try {
      let param = {
        cep: self.state.freteCep,
        peso: (self.state.produto.PESO) ? (self.state.produto.PESO *  this.state.quantity) : 0.01,
        altura: ((self.state.produto.COMPLEMENTO) && (self.state.produto.COMPLEMENTO.ALTURA)) ? self.state.produto.COMPLEMENTO.ALTURA : 0.01,
        largura: ((self.state.produto.COMPLEMENTO) && (self.state.produto.COMPLEMENTO.LARGURA)) ? self.state.produto.COMPLEMENTO.LARGURA : 0.01,
        comprimento: ((self.state.produto.COMPLEMENTO) && (self.state.produto.COMPLEMENTO.COMPRIMENTO)) ? self.state.produto.COMPLEMENTO.COMPRIMENTO : 0.01
      };

      const {data} = await self.api.post(`/shipping/modes`, param);

      if ( data.status === false ) {
        throw new Error('Não foi possível buscar opções de entrega.');
      } else {
        self.setState({freteData: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        freteData: null,
        fretehasError: true,
        freteHasErrorMsg: e.message
      });

    } finally {
      self.setState({freteIsLoading: false});
    }
  }

  private async getSimilares () {
    const self = this;

    self.setState({similarIsLoading: true});

    try {
      let param = {menu1: self.state.menu1.CDMENU};
      const {data} = await self.api.post(`/product/departamento`, param);

      if ( data.status === false ) {
        throw new Error('Não foi possível buscar produtos similares.');
      } else {
        self.setState({similarData: data.msg.data});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        similarData: null,
        similarhasError: true,
        similarHasErrorMsg: e.message
      });

    } finally {
      self.setState({similarIsLoading: false});
    }
  }

  private _henderSEOTags () {
    if ( this.state.isLoading ) {
      return (<></>);
    } else {
      const title = `${Diversos.capitalize(this.state.produto.NOME)} - ${process.env.REACT_APP_TITLE}`;
      const url = `${process.env.REACT_APP_URL}/produto/${Diversos.toSeoUrl(this.state.produto.NOME)}/${this.state.produto.CODIGO}`;
      const description = `Oferta de ${Diversos.capitalize(this.state.produto.NOME)} para compra online - ${process.env.REACT_APP_TITLE}`;
      const detalhes = ((this.state.produto.COMPLEMENTO) && (this.state.produto.COMPLEMENTO.DESCRICAO1)) ? this.state.produto.COMPLEMENTO.DESCRICAO1 : description;
      const image = ((this.state.produto.FOTOS) && (this.state.produto.FOTOS[0])) ? `${process.env.REACT_APP_BASE_URL_PICTURE}}/${this.state.produto.FOTOS[0].link}` : `${process.env.REACT_APP_BASE_URL_PICTURE}}/produto-sem-foto.png`;

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="url" content={url} />
          <meta name="robots" content="index"/>
          <meta name="description" content={description} />
          <meta name="autor" content="TecWorks" />
          <meta name="company" content="TecWorks" />
          <meta name="revisit-after" content="2" />

          {/* LD+JSON  */}
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org/",
                "@type": "Product",
                "name": "${this.state.produto.NOME}",
                "image": "${image}",
                "description": "${detalhes}",
                "mpn": "${this.state.produto.CODIGO}",
                "brand": {
                    "@type": "Thing",
                    "name": "${this.state.produto.MARCA}"
                },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "BRL",
                    "price": "${this.state.produto.PRECO}",
                    "priceValidUntil": "'. $ldJson->priceValidUntil .'",
                    "itemCondition": "http://schema.org/UsedCondition",
                    "availability": "${(this.state.produto.ESTOQUE > 0) ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'}",
                    "seller": {
                        "@type": "Organization",
                        "name": "${process.env.REACT_APP_TITLE}"
                    }
                }
              }
            `}
          </script>

          {/* TWITTER CARDS */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={detalhes} />
          <meta name="twitter:image" content={image} />

          {/* FACEBOOK CARDS */}
          <meta property="fb:app_id" content="270229570743012" />
          <meta property="og:site_name" content={title}/>
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={url}/>
          <meta property="og:title" content={title}/>
          <meta property="og:image" content={image} />
          <meta property="og:description" content={detalhes}/>
          <meta property="og:product:price:amount" content={this.state.produto.PRECO}/>
          <meta property="og:product:price:currency" content="BRL"/>
          <meta property="og:product:brand" content={this.state.produto.MARCA}/>
          <meta property="og:product:condition" content="new"/>
          <meta property="og:product:availability" content={this.state.produto.ESTOQUE > 0 ? 'in stock' : 'out stock'}/>
        </Helmet>
      );
    }
  }

  render () {
    return (
      <>
        {this._henderSEOTags()}
        <Header/>
        
        {
          !this.state.isLoading &&
          <Row className="breadcrumb-container">
            <Col>
              <Breadcrumb as={"div"}>
                <Breadcrumb.Item href="#"> Home </Breadcrumb.Item>
                {
                  this.state.menu1 &&
                  <Breadcrumb.Item href="#"> {Diversos.capitalize(this.state.menu1.DESCRICAO)} </Breadcrumb.Item>
                }
                {
                  this.state.menu2 &&
                  <Breadcrumb.Item href="#"> {Diversos.capitalize(this.state.menu2.DESCRICAO)} </Breadcrumb.Item>
                }
                {
                  this.state.menu3 &&
                  <Breadcrumb.Item href="#"> {Diversos.capitalize(this.state.menu3.DESCRICAO)} </Breadcrumb.Item>
                }
                <Breadcrumb.Item active> {Diversos.capitalize(this.state.produto.NOME)} </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        }

        {
          (!this.state.isLoading) ? 
          <>
            
            <section className="product-section">
              <Row className="pt-5 product-info-container">
                <Col lg={6} className="image-tag">
                  <Carousel interval={null}>
                    {
                      ((this.state.produto.FOTOS) && (this.state.produto.FOTOS.length > 0)) ? 
                      this.state.produto.FOTOS.map((row, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                            alt={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                          />
                        </Carousel.Item>
                      ))
                      :
                      <Carousel.Item key={0}>
                        <img
                          className="d-block w-50 m-auto"
                          src={`${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`}
                          alt={`${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`}
                        />
                      </Carousel.Item>
                    }
                  </Carousel>
                </Col>
                <Col lg={6} className="product-tags">
                  <h1 className="name-tag"> {this.state.produto.NOME} </h1>
                  <p className="brand-tag"> {this.state.produto.MARCA} </p>
                  <Row>
                    <Col lg={6} xs={6}> 
                      <p> Código: <span> {this.state.produto.CODIGO} </span> </p>
                    </Col>
                    <Col lg={6} xs={6}>
                      <p> Em estoque: <span> {(parseFloat(this.state.produto.ESTOQUE) > 0) ? this.state.produto.ESTOQUE : "Indisponível"} </span> </p>
                    </Col>
                  </Row>

                  {
                    ((this.state.produto.PREPRO > 0) && (this.state.produto.PREPRO < this.state.produto.PRECO) && (moment(this.state.produto.INIPRO) <= moment()) && (moment(this.state.produto.FIMPRO) >= moment())) ? 
                    <>
                      <p className="product-price-alt"> De R$ {Diversos.number_format(this.state.produto.PRECO,2,",","")} </p>
                      <h3 className="price-tag"> Por R$ {Diversos.number_format(this.state.produto.PREPRO,2,",","")} </h3> 
                    </>
                    :
                    <h3 className="price-tag"> R$ {Diversos.number_format(this.state.produto.PRECO,2,",","")} </h3>
                  }

                  {
                    (parseFloat(this.state.produto.ESTOQUE) > 0) ? 
                    <Container className="mt-5">
                      <Row className="align-items-center amount-container">
                        <span className="d-flex">              
                          <Col className="d-flex clear-padding amount-controller">
                            <button className="amount-btn" onClick={this.DecreaseItem}> - </button>
                          </Col>
                          <Col className="d-flex clear-padding">
                            <input type="number"
                            className="amount-input"
                            // id={this.state.quantity > 99 ? "smaller-font" : ""}
                            name="quantidade"
                            value={this.state.quantity}
                            // onChange={this.handleChange}
                            min="1"
                            max="9999"
                            step="1"
                            defaultValue="1" 
                            readOnly={true}
                            />
                          </Col>
                          <Col className="d-flex clear-padding amount-controller">
                            <button className="amount-btn" onClick={this.IncreaseItem}> + </button>
                          </Col>
                        </span>
                      </Row>
                      <Row className="buy-container">
                        <Col lg={6} className="p-0">
                          <Button className="cart-primary-action buy-action" size="lg" onClick={this.handleAddCart.bind(this)}> Comprar </Button>
                        </Col>
                      </Row>
                    </Container>
                    :
                    <Container className="mt-5">
                      <Row className="buy-container">
                        <Col className="p-0">
                          <Button className="notify-me m-auto p-0" style={{fontSize: "1rem"}} size="lg" onClick={() => this.setState({estoqueModal: true})}> Avisa-me quando chegar </Button>
                        </Col>
                      </Row>
                    </Container>
                  }

                  {/* SIMULADOR DE FRETE */}
                  <Row className="mt-5">
                    <Col xs={12} sm={12} md={8} className="calc-frete-container">
                      <span><i className="fas fa-truck"/> Simular frete <small> informe seu cep abaixo </small> </span>
                      <InputGroup className="calc-frete">
                        <InputMask mask="99.999-999" value={this.state.freteCep} onChange={(event) => this.setState({freteCep: Diversos.getnums(event.target.value)})}>
                          {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="00.000-000" className="text-center" />}
                        </InputMask>
                        <InputGroup.Append>
                          <Button variant="primary" disabled={!this.state.freteCep || this.state.freteCep.length < 8} onClick={this.handleSimularFrete.bind(this)}>
                            Calcular
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </section>
            <div className="product-details"> 
              <h2 className="details-heading"> Detalhes do produto </h2>
              <p>Altura: {this.state.produto.COMPLEMENTO.ALTURA}</p>
              <p>Largura: {this.state.produto.COMPLEMENTO.LARGURA}</p>
              <p>Comprimento: {this.state.produto.COMPLEMENTO.COMPRIMENTO}</p>
              <p style={{whiteSpace: "pre-wrap"}}>{(!this.state.produto.COMPLEMENTO) ? "Nenhuma informação adicional" : this.state.produto.COMPLEMENTO.DESCRICAO1}</p>
              <p style={{whiteSpace: "pre-wrap"}}>{(!this.state.produto.COMPLEMENTO) ? "" : this.state.produto.COMPLEMENTO.DESCRICAO2}</p>
              <p style={{whiteSpace: "pre-wrap"}}>{(!this.state.produto.COMPLEMENTO) ? "" : this.state.produto.COMPLEMENTO.DESCRICAO3}</p>
            </div>
            <div className="similar-products">
              <h2 className="details-heading"> Produtos similares </h2>
              {/* Vitrine de produtos */}
              <Row>
                <Col xs={12}>
                {
                  ((!this.state.similarIsLoading) && (this.state.similarData.length > 0)) ? 
                  <Slider 
                    dots={false}
                    arrows={true}
                    autoplay={true}
                    infinite={true}
                    speed={500}
                    slidesToShow={5}
                    slidesToScroll={1}
                    responsive={[
                      {
                        breakpoint: 1500,
                        settings: {
                          slidesToShow: 4,
                        }
                      },
                      {
                        breakpoint: 1200,
                        settings: {
                          slidesToShow: 3,
                        }
                      },
                      {
                        breakpoint: 800,
                        settings: {
                          slidesToShow: 2,
                        }
                      },
                      {
                        breakpoint: 600,
                        settings: {
                          slidesToShow: 1,
                        }
                      }
                    ]}
                  >
                  {
                    this.state.similarData.map((row, index) => {
                      if ( row.CODIGO !== this.state.produto.CODIGO ) {
                        return (<Product 
                          imageNormal={
                            ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                            : 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                          }
                          imageOver={
                            ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                              (row.FOTOS.length > 1) ? 
                              `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                              : 
                              `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                            :
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                          } 
                          item={row}
                        />);
                      }
                    })
                  }
                  </Slider>
                  :
                  ((!this.state.similarIsLoading) && (this.state.similarData.length <= 0)) ? 
                  <p> Nenhum produto localizado </p>
                  :
                  <div className="loading-similar-container">
                    <div className="mx-2 d-block"><Shimmer width="150px" height="200px"/></div>
                    <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                    <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                    <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                    <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                    <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                  </div>
                }
                </Col>
              </Row>
            </div>
          </>
          :
          <>
            <section className="product-section">
              <Row className="pt-5 product-info-container">
                <Col lg={6} className="d-flex justify-content-center">
                  <Shimmer width="300px" height="300px"/>
                </Col>
                <Col lg={6} className="product-tags">
                  <Shimmer width="100%" height="20px" className="mt-2"/>
                  <br/>
                  <Shimmer width="100%" height="20px" className="mt-2"/>
                  <br/>
                  <Shimmer width="100%" height="20px" className="mt-2"/>
                  <br/>
                  <Shimmer width="60%" height="20px" className="mt-2"/>
                  <br/>
                  <Shimmer width="75%" height="40px" className="mt-2"/>
                </Col>
              </Row>
            </section>
            <div className="product-details">
              <h2 className="details-heading"> Detalhes do produto </h2>
              <p><Shimmer width="100%" height="20px" className="mt-1"/></p>
              <p><Shimmer width="100%" height="20px" className="mt-1"/></p>
              <p><Shimmer width="75%" height="20px" className="mt-1"/></p>
              <p><Shimmer width="50%" height="20px" className="mt-1"/></p>
            </div>
            <div className="similar-products">
              <h2 className="details-heading"> Produtos similares </h2>
              <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <div className="mx-2 d-block"><Shimmer width="150px" height="200px"/></div>
                <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
                <div className="mx-2 d-none d-sm-block"><Shimmer width="150px" height="200px"/></div>
              </div>
            </div>
          </>
        }
          
        <Footer/>

        <Modal
        show={this.state.freteModal}
        onHide={() => this.setState({freteModal: false})}
        dialogClassName=""
        className="px-0"
        size={'sm'}
        centered={true}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={logo} style={{width: 60, height: 60, marginRight: 15}} alt="Logo Hidrocolombo"/>
              Preço e prazo de entrega
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {
              this.state.freteIsLoading ? 
              <>
                <p className="text-center">
                  <Lottie 
                    options={{
                      loop: true,
                      autoplay: true, 
                      animationData: Loading,
                    }}
                    height={300}
                    width={300}
                    isStopped={false}
                    isPaused={false}
                  />
                  <br/>
                  Calculando frete, por favor aguarde...
                </p>
              </>
              :
              (this.state.freteHasError || this.state.freteData === null) ? 
              <>
                <div className="alert alert-danger">
                  {this.state.freteHasErrorMsg}
                </div>
              </>
              :
              <>
                <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Serviço</th>
                        <th>Prazo</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      ((!this.state.freteData) || (this.state.freteData.length <= 0)) ?
                      <tr key={0}>
                        <td colSpan={3}>
                          <div className="alert alert-warning">
                            <i className="fas fa-warning"></i>
                            Infelizmente não entregamos este produto para o endereço informado.
                          </div>
                        </td>
                      </tr>
                      :
                      this.state.freteData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.nome}</td>
                          <td>{row.prazo}</td>
                          <td>R$ {Diversos.number_format(row.preco,2,",","")}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                </table>
              </>
            }
          </Modal.Body>
        </Modal>

        <Modal
        show={this.state.estoqueModal}
        onHide={() => this.setState({estoqueModal: false})}
        dialogClassName="sem-estoque-modal"
        className="p-0 sem-estoque-dialog"
        // size={'sm'}
        // centered={true}
        >
          <SemEstoque productName={this.state.produto.NOME}/>
        </Modal>
      </>
    );


  }
  
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Produto);