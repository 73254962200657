import React, { Component } from 'react';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Missao from '../../assets/Images/missao.png';
import Atendimento from '../../assets/Images/atendimento.png';
import Demanda from '../../assets/Images/demanda.png';
import Cadastro from '../../assets/Images/cadastro.png';
import Entrega from '../../assets/Images/entrega.png';
import lojaJuveve from '../../assets/Images/loja-juveve.jpg';
import lojaBarigui from '../../assets/Images/loja-barigui.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class Hidrocolombo extends Component <{}> {
  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mt-3"> <span className="heading-border"> A Hidrocolombo </span> </h1>
          <article className="sobre-nos-texto">
            <p>
              Tudo começou em 2005 quando a família fundou sua primeira loja no bairro Juvevê. Com o crescimento do negócio,
              abrimos nossa segunda loja no bairro Campina do Siqueira. Através de muito trabalho e honestidade com clientes e fornecedores,
              a propaganda de boca a boca cresceu. A credibilidade que tanto precisava começou a se consolidar. Com poucos recursos e sem experiência
              os desafios eram grandes. Mas, com persistência, apoio e participação da família as dificuldades foram sendo superadas.
              Hoje com duas lojas em Curitiba e nosso Centro de Distribuição em Colombo. A HIDROCOLOMBO é uma empresa que oferece soluções
              completas e eficientes no setor de materiais elétricos e construção. Focada não somente em ser fornecedor, mas sim, ajudar a desenvolver
              meios que atendam as demandas do mercado, com qualidade e competência. Possuímos uma vasta gama de produtos que oferecem economia, design
              e segurança para o consumidor. A HIDROCOLOMBO conta com um quadro de colaboradores com preparo e experiência para lhe atender:
              nossa equipe de vendas é treinada regularmente a fim de prestar a melhor assessoria aos clientes, garantindo que eles recebam, além de produtos, soluções.
            </p>
          </article>
          <article className="sobre-item">
            <div className="sobre-title">
              <img src={Missao} alt=""/>
              <h2> Missao Organizacional </h2>
            </div>
            <ul className="sobre-description">
              <li> Disponibilizar amplo mix de produtos para que o consumidor encontre os melhores itens de manutenção e reparo no mesmo local; </li>
              <hr/>
              <li> Oferecer atendimento de excelência, relacionamento de confiança e parceria; </li>
              <hr/>
              <li> Ser referência de loja no ramo de materiais para construção e ser reconhecido como marca de confiança pelos produtos de qualidade e valor justo; </li>
            </ul>
          </article>
          <article className="sobre-item">
            <div className="sobre-title">
              <img src={Atendimento} alt=""/>
              <h2> Atendimento </h2>
            </div>
            <div className="sobre-description">
              <p>
                Nos preocupamos em oferecer aos nossos clientes um atendimento diferenciado em todos os setores. Nosso comprometimento
                não é em vender produtos, mas sobretudo em oferecer soluções para a sua obra ou reparo. Para isso, contamos com uma
                equipe de profissionais treinados regularmente e preparados para prestar a melhor assessoria aos clientes,
                garantindo que eles recebam, além de produtos, soluções.
              </p>
            </div>
          </article>
          <article className="sobre-item">
            <div className="sobre-title">
              <img src={Demanda} alt=""/>
              <h2> Produtos sob Encomenda </h2>
            </div>
            <div className="sobre-description">
              <p>
                Os clientes Hidrocolombo sempre podem contar com uma grande variedade de produtos em nossas lojas. Entretanto,
                para garantir que nenhuma obra ou manutenção seja prejudicada por falta de material, também oferecemos o serviço
                de encomenda. Basta vir até uma de nossas lojas, fornecer a nossa equipe todas as especificações do produdo e nós
                entraremos em contato com a gama de fornecedores para encontrar o melhor preço e prazo de entrega.
              </p>
            </div>
          </article>
          <article className="sobre-item">
            <div className="sobre-title">
              <img src={Cadastro} alt=""/>
              <h2> Cadastro </h2>
            </div>
            <div className="sobre-description">
              <p>
                Na Hidrocolombo existe um sistema de cadastro para pessoa jurídica e condomínios, que pode ser um cadastro rápido
                ou de cliente especial, de acordo com a necessidade do cliente. O cadastro possibilita boas negociações, criando ainda mais vínculo.
              </p>
            </div>
          </article>
          <article className="sobre-item">
            <div className="sobre-title">
              <img src={Entrega} alt=""/>
              <h2> Serviços de Entregas </h2>
            </div>
            <div className="sobre-description">
              <p>
                Nossos processos internos garantem agilidade, além de qualidade e garantia do que é entregue. Entregamos em toda Curitiba e Região Metropolitana.
              </p>
            </div>
          </article>
          <Row className="sobre-lojas">
            <Col xs={12} xl={6} className="mb-5">
              <div className="lojas-title">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <h3> Loja Juvevê </h3>
              </div>
              <img src={lojaJuveve} alt="Loja Hidrocolombo no Juvevê"/>
            </Col>
            <Col xs={12} xl={6}>
              <div className="lojas-title">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <h3> Loja Barigui </h3>
              </div>
              <img src={lojaBarigui} alt="Loja Hidrocolombo no Barigui"/>
            </Col>
          </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default Hidrocolombo;
