import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import './index.scss';
import Api from '../../services/api';
import {User} from '../../store/ducks/user/types';


//Bootstrap Components
import { Button, Modal, Form } from 'react-bootstrap'
declare var window;

interface StateProps { 
  user: User;
}

interface DispatchProps {
  setParam(data: Param): void;
}

interface OwnProps {
  productName: "";
} 

type Props = StateProps & DispatchProps & OwnProps;

class SemEstoque extends Component<Props> {

  state: any = null;
  api: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      customer: null,
      novoEmail: "",
    }
  }

  componentDidMount () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status !== false )) { 
      self.getCustomer();
    }
  }

  private async getCustomer () {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status === false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private getCustomerEmail () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status !== false )) {
      if (self.state.customer) {
        return self.state.customer.email;
      }
    }
    
    return "";
  }

  render () {
    return (
      <>
        <Modal.Header closeButton className="sem-estoque-header">
          <h2> Avisa-me quando chegar </h2>
        </Modal.Header>
        <Modal.Body className="sem-estoque-body">
          <Form className="text-left">

            <Form.Group>
              <Form.Label className="ml-3"> <strong> Seu E-mail </strong> </Form.Label>
              <Form.Control
              type="email"
              placeholder="Digite seu E-mail"
              defaultValue={this.getCustomerEmail()}
              onChange={(event) => this.setState({customer: {...this.state.customer, email: event.target.value}})}
              className="user-input"/>
            </Form.Group>

            <Form.Group>
              <Form.Label className="ml-3"> <strong> Produto </strong> </Form.Label>
              <Form.Control
              type="text"
              placeholder="Digite o nome ou código do produto"
              defaultValue={this.props.productName  || ""}
              className="user-input"/>
            </Form.Group>

            <Form.Group>
              <Form.Label className="ml-3"> <strong> Observação </strong> </Form.Label>
              <Form.Control as="textarea" placeholder="Alguma observação?" className="contato-textarea"
              // onChange={(event) => this.setState({observacao: event.target.value})}
              />
            </Form.Group>
          </Form>

          <Button variant="primary" className="btn-primary-action" type="submit">
            Enviar
          </Button>

        </Modal.Body>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SemEstoque);