import React from 'react';
import './index.scss';
import { Link, Redirect } from 'react-router-dom';
import Api from '../../services/api';
import { Diversos } from '../../services/diversos';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { Param } from '../../store/ducks/param/types';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import * as ParamActions from '../../store/ducks/param/actions';
import Cart from '../Cart'
import Slider from "react-slick";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";

// Import de Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserCircle, faThLarge, faShoppingCart, faChevronDown, faBars, faMapMarkerAlt, faPhone, faReceipt } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

// Import de Imagens
import logo from '../../assets/Images/logo.png'
import whats from '../../assets/whats-app-fale-conosco.png'

// Import de Componentes Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'


declare var window;

interface StateProps {
  user: User;
  param: Param;
}

interface OwnProps {
  searchTermo?: string;
  tipo?: number;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

type Props = StateProps & DispatchProps & OwnProps;

class Header extends React.Component<Props> {

  api: any = null;
  state: any = null;
  searchbarRef: any;;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.searchbarRef = React.createRef();
    this.state = {
      isLoading: false,
      menu: null,
      searchTerm: (this.props.searchTermo) ? this.props.searchTermo : null,
      searchbarHasError: false,
      redirect: null,
      sidebarOpened: false,
      options: [
        {
          title: "Navegação",
          content: [
            {
              id: 1,
              name: "Departamentos",
              children: [{ content: [] }]
            },
            { id: 2, name: "Promoções", to: "/promocoes" },
            { id: 3, name: "Ofertas do mês", to: "/ofertas-do-mes" }
          ]
        },
        {
          title: "SAC",
          content: [
            {
              id: 5,
              name: "Minha conta",
              children: [
                {
                  content: [
                    { id: 51, name: "Meus pedidos", to: "/meus-pedidos" },
                    { id: 52, name: "Meu cadastro", to: "/meu-cadastro"},
                    { id: 53, name: "Central do Cliente", to: "/central-do-cliente" },
                    {
                      id: 54,
                      name: ((this.props.user) && (this.props.user.status === true)) ? "Reset de Senha" : "Recuperar Senha",
                      to: ((this.props.user) && (this.props.user.status === true)) ? "/meu-cadastro#trocar-senha" : "/recuperar-senha",
                    }
                  ]
                }
              ]
            },
            { id: 6, name: "Fale conosco", to: "/fale-conosco" },
            { id: 7, name: "Nossas lojas", to: "/lojas" },
            { id: 8, name: "Blog" },
            { id: 9, name: "A Hidrocolombo", to: "/hidrocolombo" }
          ]
        }
        // {
        //   content: [
        //     { id: 10, icon: <FontAwesomeIcon icon={faFacebookSquare}/>, to: "https://www.facebook.com/lojashidrocolombo/?ref=br_rs" },
        //     { id: 11, icon: <FontAwesomeIcon icon={faInstagram}/>, to: "https://www.instagram.com/lojashidrocolombo/?hl=pt-br" }
        //   ]
        // }
      ]
    }
  }



  //   you can also use this function on any of your event to open/close the sidebar
  handleSidebarToggle = isOpen => {
    this.setState({ sidebarOpened: isOpen });
  };

  handleClick = itemOptions => {
    /* 
        do something with the item you clicked.
        you can also send custom properties of your choice
        in the options array you'll be getting those here
        whenever you click that item
    */
  };


  async componentDidMount() {
    const self = this;

    self.setState({ isLoading: true });

    try {
      const { data } = await self.api.get('/menu/nivel1');

      // console.log(data);

      if ( data.status === false ) {
        throw new Error('Nao foi possível recuperar menu');
      } else {
        let aux: any = [];
        let aux2: any = [];

        for (var i = 0; i < data.msg.length; i++) {
          aux.push({
            codigo: data.msg[i].CDMENU,
            descricao: data.msg[i].DESCRICAO,
            menu2: data.msg[i].menu2,
          });

          aux2.push({
            id: i,
            name: Diversos.capitalize(data.msg[i].DESCRICAO),
            to: "/departamento/" + Diversos.toSeoUrl(data.msg[i].DESCRICAO),
          });
        }

        let tmp = self.state.options;
        tmp[0].content[0].children[0].content = aux2;

        self.setState({ menu: aux, options: tmp });
      }

    } catch (e) {
      console.error(e);
      self.setState({ menu: null });
    } finally {
      self.setState({ isLoading: false });
    }
  }

  /**
   * FAZ TRATAMENTO DO BOTAO DE PESQUISA
   */
  private async handleSearch() {
    const self = this;
    if (!self.state.searchTerm) {
      self.searchbarRef.current.focus();
      self.setState({ searchbarHasError: true });
    } else {
      self.setState({ redirect: `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}` });
      window.location.href = `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}`;
    }
  }

  render() {


    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <div className="whats-app-link">
          <a href="https://api.whatsapp.com/send?phone=5541999379910" target="_blank" rel="noopener noreferrer" title="Fale conosco pelo Whats App (41) 99937-9910">
            <img src={whats} alt="Entre em contato pelo Whatsapp"/>
          </a>
        </div>

        <div className="top-header">
          <Slider 
            className="top-header-slider"
            dots={false}
            arrows={false}
            autoplay={true}
            infinite={true}
            speed={1500}
            autoplaySpeed={6000}
            slidesToShow={1}
            slidesToScroll={1}>
            <span>
              <a href="https://goo.gl/maps/gKq3DYi1L2pLYbiX7" target="_blank" rel="noopener noreferrer" title="Localização Loja Hidrocolombo Barigui">
                <FontAwesomeIcon icon={faMapMarkerAlt}/> Loja Barigui 
              </a>
              <a href="tel:+554130228283" target="_blank" rel="noopener noreferrer" title="Ligar para Loja Hidrocolombo Barigui" className="hidden-sm-down">
                <FontAwesomeIcon icon={faPhone}/> (41) 3022-8283 
              </a>
              <a href="https://api.whatsapp.com/send?phone=554130228283" target="_blank" rel="noopener noreferrer" title="Mandar mensagem no whatsapp para Loja Hidrocolombo Barigui">
                <FontAwesomeIcon icon={faWhatsapp}/> (41) 3022-8283
              </a>
            </span>
            <span>
              <a href="https://goo.gl/maps/mrbZBhsH1pC15YXW7" target="_blank" rel="noopener noreferrer" title="Localização Loja Hidrocolombo Juveve">
                <FontAwesomeIcon icon={faMapMarkerAlt}/> Loja Juvevê 
              </a>
              <a href="tel:+554130273233" target="_blank" rel="noopener noreferrer" title="Ligar para Loja Hidrocolombo Juvevê" className="hidden-sm-down">
                <FontAwesomeIcon icon={faPhone}/> (41) 3027-3233 
              </a>
              <a href="https://api.whatsapp.com/send?phone=554130273233" target="_blank" rel="noopener noreferrer" title="Mandar mensagem no whatsapp para Loja Hidrocolombo Juvevê">
                <FontAwesomeIcon icon={faWhatsapp}/> (41) 3027-3233
              </a>
            </span>
            <span>
              <a href="https://goo.gl/maps/X7xgnyDWJoKoULQP6" target="_blank" rel="noopener noreferrer" title="Localização Loja Hidrocolombo Colombo">
                <FontAwesomeIcon icon={faMapMarkerAlt}/> Loja Colombo 
              </a>
              <a href="tel:+554135629986" target="_blank" rel="noopener noreferrer" title="Ligar para Loja Hidrocolombo Juvevê" className="hidden-sm-down">
                <FontAwesomeIcon icon={faPhone}/> (41) 3562-9986
              </a>
              <a href="https://api.whatsapp.com/send?phone=5541999379910" target="_blank" rel="noopener noreferrer" title="Mandar mensagem no whatsapp para Loja Hidrocolombo Colombo">
                <FontAwesomeIcon icon={faWhatsapp}/> (41) 99937-9910
              </a>
            </span>
          </Slider>
        </div>

        {
          ((!this.props.tipo) || (this.props.tipo !== 2)) ?
            <>
              <header>
                <div className="d-none d-lg-flex img-container">
                  <a href="/"> <img src={logo} alt="Logo Hidrocolombo"/> </a>
                </div>

                {/* Menu Desktop */}
                <nav id="lg-nav" className="d-none d-lg-flex">
                  <Row className="top-bar">
                    <Col lg={9} className="search-bar">
                      <input
                        type="text"
                        className="search-box-input"
                        placeholder="Buscar produtos em nossa loja"
                        value={this.state.searchTerm || ""}
                        onChange={(event) => this.setState({ searchTerm: event.target.value })}
                        onKeyPress={(event) => event.key === 'Enter' && this.handleSearch()} />
                      <span>
                        <p>SEG A SEXTA</p>
                        08:30 às 18:30
                      </span>
                      <span>
                        <p>SÁBADO</p>
                        08:30 às 13:00
                      </span>
                    </Col>
                    <Col lg={3} className="user-links">
                      <a className="user-link" href={((this.props.user) && (this.props.user.status === true)) ? "/meus-pedidos" : "/login"}
                      title={((this.props.user) && (this.props.user.status === true)) ?
                      "Meus pedidos" : "Fazer login"}>
                        <FontAwesomeIcon icon={faReceipt} className="header-icon"/>
                        Meus pedidos
                      </a>  
                      <a className="user-link" href={((this.props.user) && (this.props.user.status === true)) ? "/meu-cadastro" : "/login"}
                      target="_self" title={((this.props.user) && (this.props.user.status === true)) ?
                      "Meu cadastro" : "Fazer login"}>
                        <FontAwesomeIcon icon={faUser} className="header-icon"/>
                        { ((this.props.user) && (this.props.user.status === true)) ? "Meu perfil" : "Fazer Login" }
                      </a>
                      <span className="user-link" onClick={() => this.props.setParam({ ...this.props.param, cartOpened: true })}>
                        <FontAwesomeIcon icon={faShoppingCart} className="header-icon" />
                        Meu carrinho
                      </span>
                    </Col>
                  </Row>
                  <div className="bottom-bar">
                    <ul className="navigation-bar">
                      <li> <FontAwesomeIcon icon={faThLarge} className="categories-icon" />
                      Departamentos <FontAwesomeIcon icon={faChevronDown} />

                        {
                          (this.state.isLoading) ?
                            <ul className="dropdown-lvl-one">
                              <li> <FontAwesomeIcon icon="spinner" spin /> </li>
                            </ul>
                            :
                            ((this.state.menu) && (this.state.menu.length > 0)) ?
                              <ul className="dropdown-lvl-one">
                                {
                                  this.state.menu.map((row, index) => (
                                    <li key={index}>
                                      <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`} target="_self">
                                        {Diversos.capitalize(row.descricao)}
                                      </a>
                                    </li>
                                  ))
                                }
                              </ul>
                              :
                              null
                        }
                      </li>
                      <li> <a href="/promocoes" target="_self" title="Ver produtos em promoções"> Promoções </a> </li>
                      <li id="hide-nav-item"> <a href="/hidrocolombo" target="_self" title="Ver produtos em promoções"> A Hidrocolombo </a> </li>
                      <li id="hide-nav-item"> <a href="/lojas" target="_self" title="Ver produtos em promoções"> Lojas </a> </li>
                      <li> <a href="/fale-conosco" target="_self" title="Ver produtos em promoções"> Fale conosco </a> </li>
                      <li> Blog </li>
                      <li> <a href="/ofertas-do-mes" target="_self" title="Ver ofertas do mês"> Ofertas do mês </a> </li>
                    </ul>
                  </div>
                </nav>

                {/* Menu Mobile */}
                <nav id="md-nav" className="d-lg-none">
                  <Row className="w-100">
                    <Col xs={4}>
                      <span className="user-link force-left" onClick={() => this.handleSidebarToggle(true)}>
                        <FontAwesomeIcon icon={faBars} className="header-icon" />
                      </span>
                    </Col>
                    <Col xs={4} className="img-container">
                      <a href="/"> <img src={logo} alt="Logo Hidrocolombo"/> </a>
                    </Col>
                    <Col xs={4}>
                      <span className="user-link force-right" onClick={() => this.props.setParam({ ...this.props.param, cartOpened: true })}>
                        <FontAwesomeIcon icon={faShoppingCart} className="header-icon" />
                      </span>
                    </Col>
                  </Row>
                  <Row className="w-100">
                    <Col xs={12}>
                      <input 
                        type="text" 
                        className="search-box-input" 
                        placeholder="Buscar produtos em nossa loja"
                        value={this.state.searchTerm  || ""}
                        onChange={(event) => this.setState({ searchTerm: event.target.value })}
                        onKeyPress={(event) => event.key === 'Enter' && this.handleSearch()}
                      ></input>
                    </Col>
                  </Row>
                </nav>
              </header>
            </>
            :
            <>
              <header className="checkout-header">
                <Container fluid className="checkout-nav">
                  <Row className="w-100 m-auto d-flex align-items-center">
                    <Col className="d-flex justify-content-center align-items-center" lg="12" md="12" xs="12">
                      <Link to="/"> <img src={logo} className="logo" alt="logo" /> </Link>
                    </Col>
                  </Row>
                </Container>
              </header>
            </>
        }
        
        <Modal
          show={this.props.param.cartOpened}
          onHide={() => this.props.setParam({ ...this.props.param, cartOpened: false })}
          dialogClassName="cart-container"
          className="px-0"
          aria-labelledby="example-custom-modal-styling-title">
          <Cart />
        </Modal>
        <MultilevelSidebar
          open={this.state.sidebarOpened}
          onToggle={this.handleSidebarToggle}
          options={this.state.options}
          onItemClick={this.handleClick}
          wrapperClassName="sidebar-mobile-menu"
          header={
            <>
              <Container className="sidebar-header">
                <a className="sidebar-user-link" href={((this.props.user) && (this.props.user.status === true)) ? "/meu-cadastro" : "/login"}
                  target="_self" title={((this.props.user) && (this.props.user.status === true)) ?
                    "Meu cadastro" : "Fazer login"}>
                  <FontAwesomeIcon icon={faUserCircle} className="sidebar-user-icon"></FontAwesomeIcon>
                  <span className="sidebar-user-action">
                    <span className="sidebar-user-title"> Meu perfil </span>
                    { ((this.props.user) && (this.props.user.status === true)) ? <span> Dados da conta </span> : <span> Cadastre-se ou faça login </span> }
                  </span>
                </a>
              </Container>
            </>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);