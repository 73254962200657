import React, { Component } from 'react';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import lojaJuveve from '../../assets/Images/loja-juveve.jpg';
import lojaBarigui from '../../assets/Images/loja-barigui.jpg';
import lojaColombo from '../../assets/Images/centro-distribuicao.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class Hidrocolombo extends Component <{}> {
  render () {
    return (
      <>
        <Header/>

        <section className="institucional">
          <h1 className="mb-5"> <span className="heading-border"> Nossas Lojas </span> </h1>
          <Row className="mt-5 sobre-lojas">
            <Col xs={12} xl={6} className="my-5">
              <div className="lojas-title">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <h3> Loja Juvevê </h3>
              </div>
              <div className="lojas-desc">
                <p> Avenida João Gualberto, 1612. Em frente ao BIG Juvevê </p>
                <p> Fone: (41) 3027-3233   /   WhatsApp: (41) 3027-3233 </p>
              </div>
              <img src={lojaJuveve} alt="Loja Hidrocolombo no Juvevê"/>
              <a href="https://www.google.com.br/maps/place/Hidrocolombo/@-25.4140387,-49.2615564,17z/data=!3m1!4b1!4m5!3m4!1s0x94dce42384de3281:0x8f3a27069785211c!8m2!3d-25.4140387!4d-49.2593677" target="_blank" rel="noopener noreferrer" className="see-on-map"> Ver no mapa </a>
            </Col>
            <Col xs={12} xl={6} className="my-5">
              <div className="lojas-title">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <h3> Loja Barigui </h3>
              </div>
              <div className="lojas-desc">
                <p> Rua Padre Anchieta, 2618. Em frente ao Hiper Condor </p>
                <p> Fone: (41) 3022-8283   /   WhatsApp:  (41) 3022-8283 </p>
              </div>
              <img src={lojaBarigui} alt="Loja Hidrocolombo no Barigui"/>
              <a href="https://www.google.com.br/maps/place/Hidrocolombo/@-25.4348488,-49.306537,17z/data=!3m1!4b1!4m5!3m4!1s0x94dce3e9d7c8180d:0xed47fbd72969a5e4!8m2!3d-25.4348488!4d-49.3043483" target="_blank" rel="noopener noreferrer" className="see-on-map"> Ver no mapa </a>
            </Col>
            <Col xs={12} xl={6} className="my-5">
              <div className="lojas-title">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <h3> Loja Colombo - Centro de Distribuição </h3>
              </div>
              <div className="lojas-desc">
                <p> R. José Belém, 244 - Roça Grande, Colombo - PR </p>
                <p> Fone: (41) 3562-9986  /   WhatsApp:  (41) 99937-9910 </p>
              </div>
              <img src={lojaColombo} alt="Loja Hidrocolombo em Colombo"/>
              <a href="https://www.google.com/maps/uv?hl=pt-BR&pb=!1s0x94dce7db2900bf63%3A0xe1fc2c561bbd547e!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMevf4Nlfdf3OGR9r1kHLwX0Mw-HfLo0a9Nm3pI%3Dw264-h160-k-no!5shidrocolombo%20colombo%20maps%20-%20Pesquisa%20Google!15sCgIgAQ&imagekey=!1e10!2sAF1QipMevf4Nlfdf3OGR9r1kHLwX0Mw-HfLo0a9Nm3pI&sa=X&ved=2ahUKEwiFsqb3x6rrAhVGIbkGHYtfCnwQoiowG3oECBYQBg" target="_blank" rel="noopener noreferrer" className="see-on-map"> Ver no mapa </a>
            </Col>
          </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default Hidrocolombo;
