import React, { Component } from 'react';
import './index.scss';
import { OrderType } from '../../store/ducks/order/types';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';


interface Props { 
  order: OrderType;
} 


class Central extends Component <Props> {

  state: any = {};

  constructor (props: any) {
    super(props);

    this.state = {
      pedido: this.props.order
    }
  }

  render () {
    return (
      <>

        <Header/>

        <section className="trocas-e-devolucoes">
          <h1 className="mt-3"> <span className="heading-border"> Frete e Envio </span> </h1>
          <article className="canais-atendimento-container">
            <h2> Sobre o envio </h2>
            <p>
              Normalmente, as compras feitas com cartões de crédito são autorizadas em até 24 horas e as realizadas com boletos têm aprovação concretizada em até 72 horas.
              Somente após a confirmação do pagamento de sua compra que o processo de envio será iniciado. Logo depois de fazer o pedido, você receberá um e-mail de confirmação
              de pagamento. Assim que o pedido for despachado, você receberá uma notificação com o número do registro para poder rastrear a sua mercadoria no site dos Correios
              ou Transportadora escolhida. Todas as entregas tem seguro contra avarias e extravio, assim conseguimos garantir a entrega ou o reembolso do seu pedido
            </p>
          </article>
          
          <hr/>

          <article className="mb-5 canais-atendimento-container">
            <h2> Modo de Entrega </h2>
            <p>
              A Hidrocolombo conta com diversas modalidades de entrega. Contamos com: RETIRE NA LOJA, ENTREGA PRÓPRIA e uma parceria especial com os CORREIOS E TOTAL EXPRESS,
              que agilizam as entregas de nossa Loja Virtual. Confira as seguintes formas de entrega e seus respectivos prazos: 
            </p>
          </article>

          <article className="tipos-de-entrega">
            <h3> Retirar na Loja – 2 a 3 dias Úteis </h3>
            <p>
              Você pode efetuar sua compra pela internet e optar por retirar seu produto em nossa loja física. Basta selecionar essa opção no momento de finalizar sua compra.
              Após a conclusão do processo normal do pedido, você será avisado que o produto está disponível para ser retirado.
            </p>
            <big className="important-call"> <FontAwesomeIcon icon={faExclamation}/> IMPORTANTE </big>
            <p>
              Devido aos produtos ficarem armazenados no estoque, os prazos para retirar os produtos são iguais aos de entrega convencional. 
            </p>
          </article>

          <article className="tipos-de-entrega">
            <h3> Entrega Própria – 2 a 3 dias Úteis </h3>
            <p>
              As entregas serão realizadas para Curitiba, Colombo, Pinhais e Almirante Tamandaré. O período de entrega é de segunda a sábado, das 8h às 20h.
              O prazo de entrega varia de acordo com o local, mercadoria, peso e dimensões e começa a valer após o faturamento do pedido, o que depende da forma
              de pagamento escolhida. A DANFE será entregue junto ao produto.
            </p>
          </article>

          <article className="tipos-de-entrega">
            <h3> Total Express – 5 a 23 dias </h3>
            <p>
              Transporte rodoviário disponibilizado para diversas localidades do país. O custo é atrativo para o prazo de despacho, que varia de 5 a 23 dias.
              Nas regiões não atendidas eles realizam o transporte até a cidade mais próxima e redespacho através dos Correios, por Sedex. 
            </p>
          </article>

          <article className="tipos-de-entrega">
            <h3> PAC – Correios – 8 a 10 dias Úteis * </h3>
            <p>
              É o serviço de encomendas padrão dos Correios. O prazo estipulado é de 8 a 10 dias úteis para entrega após a postagem, porém esse é um prazo estimado,
              ou seja, não garantido pelos Correios. O prazo máximo desse serviço é 20 dias úteis. 
            </p>
          </article>

          <article className="tipos-de-entrega">
            <h3> Sedex – Correios – 3 a 11 dias Úteis </h3>
            <p>
              O prazo de entrega é de 3 a 11 dias úteis, garantido após a postagem.
            </p>
          </article>




          <article className="my-5 nota-correios">
            <h2> Nota dos Correios: </h2>
            <ul className="warn-list">
              <li>
                Os Correios não efetuam a entrega domiciliar em algumas cidades, em área rural, logradouros de difícil acesso ou de risco.
                Caso o objeto seja destinado a uma área com restrições de entrega domiciliar, os Correios avaliarão qual a providência a ser tomada para o
                CEP indicado, podendo alongar o prazo de entrega do objeto em mais 7 dias ou enviar o objeto para uma das unidades dos Correios, de modo que
                o destinatário possa retirá-lo. Os Correios deixarão um aviso* de chegada no endereço do destinatário, que deverá se dirigir à unidade dos Correios indicada,
                portando documentos que permitam a sua identificação, para fazer a retirada do objeto. O prazo de guarda é de 7 dias corridos para SEDEX e PAC.
              </li>
              <li>
                É muito importante verificar o prazo de guarda do objeto na unidade dos Correios, pois após o prazo mencionado, o objeto será devolvido para a Hidrocolombo
                e um novo frete será cobrado para reenvio.
              </li>
            </ul>
            <em>
              * Em alguns casos, os Correios não enviam o aviso de chegada para o endereço do destinatário, por isso é muito importante rastrear seu pedido periodicamente
              (o código de rastreamento é enviado por e-mail).
            </em>
          </article>


          <hr/>

          <article className="frete-gratis-container">
            <h2> Frete Grátis </h2>
            <strong>
              A Hidrocolombo, fornece FRETE GRÁTIS para entregas em algumas regiões e cidades específicas e para produtos selecionados. 
            </strong>
            <p> 
              O frete de cada compra realizada na Hidrocolombo varia de acordo com a localidade e o peso/volume do produto. Na Hidrocolombo o cálculo do frete é individual por produto,
              logo, caso o pedido de compra contenha um produto com frete grátis e outro sem frete grátis, o valor do frete será calculado apenas para o produto fora da promoção de frete grátis. 
            </p>
            <p> 
              Por esses motivos, pode acontecer de um item estar disponível para um determinado CEP com valor de frete diferente do praticado para outro, assim como é possível
              que um produto esteja promocionado com frete grátis para determinado CEP e indisponível para outro. O mesmo vale para a disponibilidade do item, ou seja, alguns
              produtos podem estar disponíveis para determinados CEP’s de entrega e para outros não.
            </p>

            <h3> Sobre promoções anunciando “Frete Grátis”: </h3>
            <p> 
              Observadas as informações acima, se o item que você deseja está anunciado com ‘frete grátis’ em banners promocionais do site, você pode confirmar se essa condição está disponível para
              o seu CEP antes de fechar a compra. Para isso, basta digitar o CEP de entrega no campo indicado para cálculo de frete na página do produto.
            </p>
          </article>
        </section>

        <Footer/>

      </>
    );
  }
}

export default Central;
