import React from 'react';
import './index.scss';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Image, Container } from 'react-bootstrap';
import Illustration from '../../assets/Images/illustration-404.png';


class NoMatchPage extends React.Component<{}> {

  render () {
    return (
      <>
        <Header/>
        <section className="no-match-page">
          <h1 className="title-404"> A página que você está procurando não existe </h1>
          <Container className="mt-5 text-center">
            <Image src={Illustration} className="illustra-404"/>
          </Container>
          <Container className="text-center causas-container">
            <p className="causas-titulo"> Algumas possíveis causas são: </p>
            <ul className="causas">
              <li className=""> O endereço do site contenha erros de digiação </li>
              <span className="moved"> movido para outro lugar </span>
              <li>  O conteúdo desse endereço tenha sido </li> 
              <li className="never-existed"> A página nunca chegou a existir </li>
            </ul>
            <button className="back-home">
            <a href="/" className="back-home"> Voltar para a Home </a>
          </button>
          </Container>
        </section>

        <Footer/>
      </>
    );
  }
}

export default NoMatchPage;