import {combineReducers} from 'redux';
import user from './user';
import param from './param';
import cart from './cart';
import order from './order';

export default combineReducers({
  user,
  param,
  cart,
  order,
});