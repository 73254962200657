import React from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User,} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Redirect } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from 'react-input-mask'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import {Spinner} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'


interface StateProps { 
  user: User;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

// const { password } = this.state;

class Cadastro extends React.Component<Props> {

  api: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;
  formTop: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.novoCepRef = React.createRef();
    this.novoRuaRef = React.createRef();
    this.novoNumeroRef = React.createRef();
    this.novoBairroRef = React.createRef();
    this.novoCidadeRef = React.createRef();
    this.novoUfRef = React.createRef();
    this.novoComplementoRef = React.createRef();
    this.formTop = React.createRef();

    this.state = {
      redirect: null,
      
      // VARIAVEIS DE CONTROLE PARA FORM DE NOVO CADASTRO
      isLoadingNovo: false,
      novoNome: null,
      novoCpf: null,
      novoTelefone: null,
      novoEmail: null,
      novoSenha: null,
      novoConfSenha: null,
      novoCep: null,
      novoRua: null,
      novoNumero: null,
      novoBairro: null,
      novoCidade: null,
      novoEstado: null,
      novoComplemento: null,
      novoHasError: false,
      novoHasErrorTitle: null,
      novoHasErrorMsg: null,
      currentStep: 1,
      email:  '',
      username: '',
      password: '', 

      // VARIAVEIS DE CONTROLE PARA FORM DE CADASTRO JA EXISTENTE
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,

      isLoadingCep: false
    }
  }

  componentDidMount () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status === true )) { 
      self.setState({redirect: '/meu-cadastro'});
    }
  }

  stepStyling (step) {
    if (step === this.state.currentStep) {
      return 'step-active';
    }
    else if (step < this.state.currentStep) {
      return 'step-passed';
    }
    return 'step-inactive';
  }

  private async getAddressByCep () {
    const self = this;

    if ( !self.state.novoCep )
      return false;

    self.setState({isLoadingCep: true});

    let param = {cep : self.state.novoCep}

    try {
      const {data} = await self.api.post('/shipping/cep', param);

      if ( data.status ) {
        self.setState({
          novoRua: data.msg.logradouro,
          novoBairro: data.msg.bairro,
          novoCidade: data.msg.localidade,
          novoEstado: data.msg.uf
        });
      }

    } catch (e) {
      console.error(`ERROR: /shipping/cep: ${e}}`);
    } finally {
      self.setState({isLoadingCep: false});
    }
  }

  scrollToRef = () => window.scrollTo(0, 100);
  // scrollToRef = () => window.scrollTo(0, this.formTop.current.offSetTop);


  // FUNCAO PARA TRATAR INCLUSAO DE CADASTRO
  private async handleSubmitNovo (pressedButton) {
    const self = this;

    this.scrollToRef();
      
    if (this.state.currentStep === 1) {

      if (( !self.state.novoEmail ) || ( !self.state.novoSenha ) || ( !self.state.novoConfSenha )) {
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      } 

      if ( self.state.novoSenha !== self.state.novoConfSenha ) {
        self.setMsg( 'error', "Confirmação da senha", `A confirmação da senha não confere com a senha informada.`, 2);
        return;
      }

      this.setState({novoHasError: false, currentStep: 2});
      return;
    }
    
    if (this.state.currentStep === 2) {

      if (pressedButton === 1) {
        this.setState({
          currentStep: this.state.currentStep - 1
        });
        return;
      }

      if (( !self.state.novoNome ) || ( !self.state.novoCpf) || ( !self.state.novoCelular ) || ( !self.state.novoNascimento )) {
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }

      if ( !Diversos.validateCPF(self.state.novoCpf) ) {
        self.setMsg( 'error', "CPF inválido", `Necessário informar um CPF válido para continuar.`, 2);
      }

      this.setState({novoHasError: false, currentStep: 3});
      return;
    }

    if (this.state.currentStep === 3) {

      if (pressedButton === 1) {
        this.setState({
          currentStep: this.state.currentStep - 1
        });
        return;
      } 

      if (( !self.state.novoCep ) || ( !self.state.novoRua ) ||
        ( !self.state.novoNumero ) || ( !self.state.novoBairro ) ||
        ( !self.state.novoCidade ) || ( !self.state.novoEstado ) ) {

        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }
    }

    self.setState({ novoHasError: false, isLoadingNovo: true });

    let param = {
      email: self.state.novoEmail,
      senha: self.state.novoSenha,
      nome: self.state.novoNome,
      cpf: self.state.novoCpf,
      nascimento: self.state.novoNascimento,
      telefone: self.state.novoTelefone,
      celular: self.state.novoCelular,
      cep: self.state.novoCep,
      rua: self.state.novoRua,
      numero: self.state.novoNumero,
      bairro: self.state.novoBairro,
      cidade: self.state.novoCidade,
      estado: self.state.novoEstado,
      complemento: self.state.novoComplemento
    }

    try {
      const {data} = await self.api.post('/customer', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cpf,
          status: true,
          avatar: '',
          token: null,
        });

        setTimeout(() => {
          self.setState({
            redirect: '/',
          });
        }, 400)
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível criar conta. ${e.message}`, 2);
    } finally {
      self.setState({ isLoadingNovo: false });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type 
   * @param title 
   * @param msg 
   * @param form 
   */
  private async setMsg ( type: string, title: string, msg: string, form: number ) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {
      if ( form === 1 ) {
        self.setState({
          customerHasError: true,
          customerHasErrorTitle: title,
          customerHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasError: false }), timeout)
        });
      } else {
        self.setState({
          novoHasError: true,
          novoHasErrorTitle: title,
          novoHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasError: false }), timeout)
        });
      }
    } else {
      if ( form === 1 ) {
        self.setState({
          customerHasSuccess: true,
          customerHasSuccessTitle: title,
          customerHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
        });
      } else {
        self.setState({
          novoHasSuccess: true,
          novoHasSuccessTitle: title,
          novoHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasSuccess: false }), timeout)
        });
      }
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
        <>
        <Header/>

        <section className="login-container" id="unlogged-user-login">
          <div className="form-container" id="container-user-signup">
            <h1 className="mt-3"> <span className="heading-border"> Cadastro </span> </h1>

            {
              this.state.novoHasError &&
              <div className="mt-4 mb-3 alert alert-danger">
                <strong> {this.state.novoHasErrorTitle} </strong>
                <br/>
                {this.state.novoHasErrorMsg}
              </div>
            }

            {/* LINHA COM STEPS */}
            <Row className="form-current-step">
              <Col xs={4} sm={3} className="d-flex text-center">
                <span>
                  <h2 className={this.stepStyling(1)}> 1 </h2>
                  <p className={this.stepStyling(1)}> Dados da conta </p>
                </span>
              </Col>
              <Col xs={1} sm={1} className="d-none d-sm-flex hr-col">
                <hr className={this.stepStyling(1)}/>
              </Col>
              <Col xs={4} sm={3} className="d-flex text-center">
                <span>
                  <h2 className={this.stepStyling(2)}> 2 </h2>
                  <p className={this.stepStyling(2)}> Dados pessoais </p>
                </span>
              </Col>
              <Col xs={1} sm={1} className="d-none d-sm-flex hr-col">
                <hr className={this.stepStyling(2)}/>
              </Col>
              <Col xs={4} sm={3} className="d-flex text-center">
                <span> 
                  <h2 className={this.stepStyling(3)}> 3 </h2>
                  <p className={this.stepStyling(3)}> Dados de entrega </p>
                </span>
              </Col>
            </Row>

            <div className="input-container" id="unlogged-user-input">
              <Form className="d-flex w-100">
                <Col className="form-col">

                { 
                  (this.state.currentStep !== 1) ?
                    null 
                  :
                    <>
                      <Form.Row ref={this.formTop}>
                        <Form.Group as={Col} className="mb-3">
                          <Form.Label className="ml-3"> Email *</Form.Label>
                          <Form.Control type="email" placeholder="Email" className="user-input" value={this.state.novoEmail  || ""} onChange={(event) => this.setState({novoEmail: event.target.value})} required/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label className="ml-3"> Senha *</Form.Label>
                          <Form.Control type="password" placeholder="Senha" className="user-input" value={this.state.novoSenha  || ""} onChange={(event) => this.setState({novoSenha: event.target.value})} required/>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label className="ml-3"> Confirme sua Senha *</Form.Label>
                          <Form.Control type="password" placeholder="Senha" className="user-input" value={this.state.novoConfSenha  || ""} onChange={(event) => this.setState({novoConfSenha: event.target.value})} required/>
                        </Form.Group>
                      </Form.Row>
                    </>
                }
                  
                { 
                  (this.state.currentStep !== 2) ?
                    null 
                  :
                    <>
                      <Form.Row ref={this.formTop}>
                        <Form.Group as={Col} className="mb-3">
                          <Form.Label className="ml-3"> Nome *</Form.Label>
                          <Form.Control type="text" placeholder="Nome" value={this.state.novoNome  || ""} className="user-input" onChange={(event) => this.setState({novoNome: event.target.value})} required/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} className="mb-3">
                          <Form.Label className="ml-3"> CPF *</Form.Label>
                          <InputMask mask="999.999.999-99" value={this.state.novoCpf  || ""} onChange={(event) => this.setState({novoCpf: event.target.value})}>
                            {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="000.000.000-00" className="user-input" required/>}
                          </InputMask>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label className="meu-cadastro-label"> Data de Nascimento * </Form.Label>
                          <InputMask mask="99/99/9999" value={this.state.novoNascimento  || ""} onChange={(event) => this.setState({novoNascimento: event.target.value})} required>
                            {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="99/99/9999" className="user-input"/>}
                          </InputMask>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} className="mb-3">
                          <Form.Label className="ml-3"> Telefone 1 *</Form.Label>
                          <InputMask mask="(99) 9 9999-9999" value={this.state.novoCelular  || ""} onChange={(event) => this.setState({novoCelular: event.target.value})}>
                            {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(00) 00000-0000" className="user-input" required/>}
                          </InputMask>
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6}>
                          <Form.Label className="meu-cadastro-label"> Telefone 2 </Form.Label>
                          <InputMask mask="(99) 9 9999-9999" value={this.state.novoTelefone  || ""} onChange={(event) => this.setState({novoTelefone: event.target.value})}>
                            {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(41) 9-9999-9999" className="user-input"/>}
                          </InputMask>
                        </Form.Group>
                      </Form.Row>
                    </>
                } 

                { 
                  (this.state.currentStep !== 3) ?
                    null 
                  :
                    <>
                      <Form.Row ref={this.formTop}>
                        <Form.Group as={Col} xs={12}>
                          <Form.Label className="meu-cadastro-label"> CEP * </Form.Label>
                          <InputMask 
                          mask="99.999-999"
                          ref={this.novoCepRef}
                          value={this.state.novoCep  || ""}
                          disabled={this.state.isLoadingCep}
                          onChange={(event) => {
                          let tmp = Diversos.getnums(event.target.value);
                          if ( tmp.length >= 8 )
                            this.setState({novoCep: event.target.value}, this.getAddressByCep.bind(this))
                          else
                            this.setState({novoCep: event.target.value})
                          }}>
                            {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="00.000-000" className="user-input" required/>} 
                          </InputMask>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} xs={12} md={8}>
                          <Form.Label className="meu-cadastro-label"> Rua * </Form.Label>
                          <Form.Control placeholder="Avenida Paulista" className="user-input" value={this.state.novoRua  || ""} ref={this.novoRuaRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoRua: event.target.value})} required/>
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={4}>
                          <Form.Label className="meu-cadastro-label"> Número * </Form.Label>
                          <Form.Control className="user-input" value={this.state.novoNumero  || ""} ref={this.novoNumeroRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoNumero: event.target.value})} required/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label className="meu-cadastro-label"> Bairro * </Form.Label>
                          <Form.Control className="user-input" value={this.state.novoBairro  || ""} ref={this.novoBairroRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoBairro: event.target.value})} required/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label className="meu-cadastro-label"> Cidade * </Form.Label>
                          <Form.Control placeholder="São Paulo" value={this.state.novoCidade  || ""} ref={this.novoCidadeRef} disabled={this.state.isLoadingCep} className="user-input" onChange={(event) => this.setState({novoCidade: event.target.value})} required/>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label className="meu-cadastro-label"> Estado * </Form.Label>
                          <Form.Control as="select" className="user-input" value={this.state.novoEstado  || ""} ref={this.novoUfRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoEstado: event.target.value})} required>
                          {
                            Diversos.getUFs().map((row, index) => (
                              <option key={index} value={row.value || ""}> {row.label} </option>
                            ))
                          }
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label className="meu-cadastro-label"> Complemento </Form.Label>
                          <Form.Control placeholder="Ap. 7" className="user-input" value={this.state.novoComplemento  || ""} onChange={(event) => this.setState({novoComplemento: event.target.value})}/>
                        </Form.Group>
                      </Form.Row>
                    </>
                } 

                  <Row className="multistep-controller">
                  {
                    (this.state.currentStep !== 1)  ?
                      <button
                      className="btn btn-link-action text-center mx-2"
                      type="button"
                      onClick={this.handleSubmitNovo.bind(this, 1)}>
                        <FontAwesomeIcon icon={faArrowLeft} className="mx-1"/> Voltar
                      </button>
                    :
                      null
                  }

                  {
                    (this.state.currentStep < 3) ?
                      <button
                      className="btn btn-link-action mx-2"
                      type="button"
                      onClick={this.handleSubmitNovo.bind(this, 2)}>
                        Próximo <FontAwesomeIcon icon={faArrowRight} className="mx-1" />
                      </button>
                    :
                      null
                  }
                  </Row>
                </Col>
              </Form>
            </div>

            {
              (this.state.currentStep === 3) ?
                <button type="submit" className="mt-2 btn btn-lg primary-action" onClick={this.handleSubmitNovo.bind(this)} disabled={this.state.isLoadingNovo}>
                {
                  this.state.isLoadingNovo ?
                    <>
                      <Spinner animation="border" role="status" variant="light" size="sm">
                        <span className="sr-only"> Loading... </span>
                      </Spinner>
                    </>
                  :
                    <> Cadastrar </>
                }
                </button>
              :
                null
            }

            <h3 className="mt-5"> <span className="font-size-16"> Possui uma conta? </span> <a href="/login"> Faça Login </a> </h3>
          </div>              
        </section>
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cadastro);